import {
  ERROR_CARRIER_SOURCING_LIST,
  ERROR_DELETE_FILTERS,
  ERROR_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  ERROR_GET_COLUMN_ORDER_FILTER,
  ERROR_GET_FILTERS,
  ERROR_SAVE_CARRIER_RATING,
  ERROR_SAVE_COLUMN_ORDER,
  ERROR_SAVE_FILTERS,
  ERROR_TEMPLATE_LIST_,
  ERROR_UPDATE_CARRIER_DETAILS,
  SUCCESS_CARRIER_SOURCING_LIST,
  SUCCESS_DELETE_FILTERS,
  SUCCESS_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  SUCCESS_GET_COLUMN_ORDER_FILTER,
  SUCCESS_GET_FILTERS,
  SUCCESS_SAVE_CARRIER_RATING,
  SUCCESS_SAVE_COLUMN_ORDER,
  SUCCESS_SAVE_FILTERS,
  SUCCESS_TEMPLATE_LIST_,
  SUCCESS_UPDATE_CARRIER_DETAILS,
  SUCCESS_ORIGIN_SEARCH_LIST,
  ERROR_ORIGIN_SEARCH_LIST,
  SUCCESS_DESTINATION_SEARCH_LIST,
  ERROR_DESTINATION_SEARCH_LIST,
  SUCCESS_SEARCH_CARRIER_LIST,
  ERROR_SEARCH_CARRIER_LIST,
  SUCCESS_ORIGIN_DESTINATION_DETAIL,
  ERROR_ORIGIN_DESTINATION_DETAIL,
  SUCCESS_CARRIER_DETAIL,
  ERROR_CARRIER_DETAIL,
  SUCCESS_UPDATE_FILTERS,
  ERROR_UPDATE_FILTERS,
  SUCCESS_UPDATE_BASIC_DETAIL,
  ERROR_UPDATE_BASIC_DETAIL,
  SUCCESS_UPDATE_DISPATCHER_CONTACT,
  ERROR_UPDATE_DISPATCHER_CONTACT,
  SUCCESS_UPDATE_OPERATIONS,
  ERROR_UPDATE_OPERATIONS,
  SUCCESS_UPDATE_CARRIER_CONTACT,
  ERROR_UPDATE_CARRIER_CONTACT,
  SUCCESS_UPDATE_AUTHORITY_DETAIL,
  ERROR_UPDATE_AUTHORITY_DETAIL,
  SUCCESS_UPDATE_FOCUS_AREA,
  ERROR_UPDATE_FOCUS_AREA,
  SUCCESS_SAVE_NOTES,
  ERROR_SAVE_NOTES,
  ERROR_MY_DELETE_CARRIER,
  SUCCESS_MY_DELETE_CARRIER,
  SUCCESS_MY_CARRIER_GET,
  ERROR_MY_CARRIER_GET,
  SUCCESS_UPDATE_MY_CARRIER_DETAIL,
  ERROR_UPDATE_MY_CARRIER_DETAIL,
  SUCCESS_MY_CARRIER_ADD,
  ERROR_MY_CARRIER_ADD,
  SUCCESS_GET_MY_CARRIER_BY_ID,
  ERROR_GET_MY_CARRIER_BY_ID,
  SUCCESS_GET_MARKET_DATA_GRAPH,
  ERROR_GET_MARKET_DATA_GRAPH,
  SUCCESS_GET_ADDITIONAL_MARKET_DATA,
  ERROR_GET_ADDITIONAL_MARKET_DATA,
  SUCCESS_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  ERROR_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  SUCCESS_HEADQUARTERS_SEARCH_LIST,
  ERROR_HEADQUARTERS_SEARCH_LIST,
  SUCCESS_ADVANCE_SEARCH_EQUIPMENT_LIST,
  ERROR_ADVANCE_SEARCH_EQUIPMENT_LIST,
  SUCCESS_ADVANCE_MARKET_EQUIPMENT_LIST,
  ERROR_ADVANCE_MARKET_EQUIPMENT_LIST,
  SUCCESS_DASHBOARD_DATA_SOURCE_LIST,
  ERROR_DASHBOARD_DATA_SOURCE_LIST,
  SUCCESS_DASHBOARD_USER_LIST,
  ERROR_DASHBOARD_USER_LIST,
  SUCCESS_CARRIER_USER_LIST,
  ERROR_CARRIER_USER_LIST,
  SUCCESS_LANES_AND_MAP_LIST,
  ERROR_LANES_AND_MAP_LIST,
  SUCCESS_NOTES_DELETE_DASHBOARD,
  ERROR_NOTES_DELETE_DASHBOARD,
  SUCCESS_HEADQUARTERS_SEARCH_VALUE_LIST,
  ERROR_HEADQUARTERS_SEARCH_VALUE_LIST,
} from "./dashboard.action";

import { DEFAULT_STATE } from "./dashboard.state";

export const dashboardReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_CARRIER_SOURCING_LIST:
      const carrierSourcingListData = action.data;
      return { ...state, carrierSourcingListData };
    case ERROR_CARRIER_SOURCING_LIST:
      const errorCarrierSourcingListData = action.data;
      return {
        ...state,
        carrierSourcingListData: errorCarrierSourcingListData,
      };

    case SUCCESS_SAVE_FILTERS:
      const successSaveFiltersData = action.data;
      return { ...state, successSaveFiltersData };
    case ERROR_SAVE_FILTERS:
      const errorSuccessSaveFiltersData = action.data;
      return { ...state, successSaveFiltersData: errorSuccessSaveFiltersData };

    case SUCCESS_GET_FILTERS:
      const getFiltersData = action.data;
      return { ...state, getFiltersData };
    case ERROR_GET_FILTERS:
      const errorGetFiltersData = action.data;
      return { ...state, getFiltersData: errorGetFiltersData };

    case SUCCESS_DELETE_FILTERS:
      const deleteFiltersData = action.data;
      return { ...state, deleteFiltersData };
    case ERROR_DELETE_FILTERS:
      const errorDeleteFiltersData = action.data;
      return { ...state, deleteFiltersData: errorDeleteFiltersData };

    case SUCCESS_SAVE_CARRIER_RATING:
      const saveCarrierRatingsData = action.data;
      return { ...state, saveCarrierRatingsData };
    case ERROR_SAVE_CARRIER_RATING:
      const errorSaveCarrierRatingsData = action.data;
      return { ...state, saveCarrierRatingsData: errorSaveCarrierRatingsData };

    case SUCCESS_TEMPLATE_LIST_:
      const templateListDataValue = action.data;
      return { ...state, templateListDataValue };
    case ERROR_TEMPLATE_LIST_:
      const errorTemplateListDataData = action.data;
      return { ...state, templateListDataValue: errorTemplateListDataData };

    case SUCCESS_DOWNLOAD_CARRIER_SOURCING_EXCEL:
      const carrierSourcingDownloadExcelData = action.data;
      return { ...state, carrierSourcingDownloadExcelData };
    case ERROR_DOWNLOAD_CARRIER_SOURCING_EXCEL:
      const errorCarrierSourcingDownloadExcelData = action.data;
      return {
        ...state,
        carrierSourcingDownloadExcelData: errorCarrierSourcingDownloadExcelData,
      };

    // save notes
    case SUCCESS_SAVE_NOTES:
      const savenotesdata = action.data;
      return { ...state, savenotesdata };
    case ERROR_SAVE_NOTES:
      const errorsavenotes = action.data;
      return { ...state, savenotesdata: errorsavenotes };

    //updateCarrierDetailsData
    case SUCCESS_UPDATE_CARRIER_DETAILS:
      const updateCarrierDetailsData = action.data;
      return { ...state, updateCarrierDetailsData };
    case ERROR_UPDATE_CARRIER_DETAILS:
      const errorupdateCarrierDetailsData = action.data;
      return {
        ...state,
        updateCarrierDetailsData: errorupdateCarrierDetailsData,
      };

    //getColumnOrderFilter
    case SUCCESS_GET_COLUMN_ORDER_FILTER:
      const getColumnOrderFilterData = action.data;
      return { ...state, getColumnOrderFilterData };
    case ERROR_GET_COLUMN_ORDER_FILTER:
      const errorGetColumnOrderFilterData = action.data;
      return {
        ...state,
        getColumnOrderFilterData: errorGetColumnOrderFilterData,
      };

    case SUCCESS_SAVE_COLUMN_ORDER:
      const saveColumnOrderData = action.data;
      return { ...state, saveColumnOrderData };
    case ERROR_SAVE_COLUMN_ORDER:
      const errorSaveColumnOrderData = action.data;
      return { ...state, saveColumnOrderData: errorSaveColumnOrderData };

    case SUCCESS_ORIGIN_SEARCH_LIST:
      const originSearchList = action.data;
      return { ...state, originSearchList };
    case ERROR_ORIGIN_SEARCH_LIST:
      const errorOriginSearchList = action.data;
      return { ...state, originSearchList: errorOriginSearchList };

    case SUCCESS_DESTINATION_SEARCH_LIST:
      const destinationSearchList = action.data;
      return { ...state, destinationSearchList };
    case ERROR_DESTINATION_SEARCH_LIST:
      const errorDestinationSearchList = action.data;
      return { ...state, destinationSearchList: errorDestinationSearchList };

    case SUCCESS_SEARCH_CARRIER_LIST:
      const searchCarrierList = action.data;
      return { ...state, searchCarrierList };
    case ERROR_SEARCH_CARRIER_LIST:
      const errorSearchCarrierList = action.data;
      return { ...state, searchCarrierList: errorSearchCarrierList };

    case SUCCESS_ORIGIN_DESTINATION_DETAIL:
      const originDestinationDetail = action.data;
      return { ...state, originDestinationDetail };
    case ERROR_ORIGIN_DESTINATION_DETAIL:
      const errorOriginDestinationDetail = action.data;
      return {
        ...state,
        originDestinationDetail: errorOriginDestinationDetail,
      };

    case SUCCESS_CARRIER_DETAIL:
      const carrierDetailData = action.data;
      return { ...state, carrierDetailData };
    case ERROR_CARRIER_DETAIL:
      const errorCarrierDetailData = action.data;
      return { ...state, carrierDetailData: errorCarrierDetailData };

    case SUCCESS_UPDATE_FILTERS:
      const successUpdateFiltersData = action.data;
      return { ...state, successUpdateFiltersData };
    case ERROR_UPDATE_FILTERS:
      const errorSuccessUpdateFiltersData = action.data;
      return {
        ...state,
        successUpdateFiltersData: errorSuccessUpdateFiltersData,
      };

    case SUCCESS_UPDATE_BASIC_DETAIL:
      const successUpdateBasicDetailData = action.data;
      return { ...state, successUpdateBasicDetailData };
    case ERROR_UPDATE_BASIC_DETAIL:
      const errorSuccessUpdateBasicDetailData = action.data;
      return {
        ...state,
        successUpdateBasicDetail: errorSuccessUpdateBasicDetailData,
      };

    case SUCCESS_UPDATE_OPERATIONS:
      const successUpdateOperationsData = action.data;
      return { ...state, successUpdateOperationsData };
    case ERROR_UPDATE_OPERATIONS:
      const errorSuccessUpdateOperationsData = action.data;
      return {
        ...state,
        successUpdateOperations: errorSuccessUpdateOperationsData,
      };

    case SUCCESS_UPDATE_CARRIER_CONTACT:
      const successUpdateCarrierContactData = action.data;
      return { ...state, successUpdateCarrierContactData };
    case ERROR_UPDATE_CARRIER_CONTACT:
      const errorSuccessUpdateCarrierContactData = action.data;
      return {
        ...state,
        successUpdateCarrierContact: errorSuccessUpdateCarrierContactData,
      };

    case SUCCESS_UPDATE_DISPATCHER_CONTACT:
      const successUpdateDispatcherContactData = action.data;
      return { ...state, successUpdateDispatcherContactData };
    case ERROR_UPDATE_DISPATCHER_CONTACT:
      const errorSuccessUpdateDispatcherContactData = action.data;
      return {
        ...state,
        successUpdateDispatcherContact: errorSuccessUpdateDispatcherContactData,
      };

    case SUCCESS_UPDATE_AUTHORITY_DETAIL:
      const successUpdateAuthorityDetailData = action.data;
      return { ...state, successUpdateAuthorityDetailData };
    case ERROR_UPDATE_AUTHORITY_DETAIL:
      const errorSuccessUpdateAuthorityDetailData = action.data;
      return {
        ...state,
        successUpdateAuthorityDetailData: errorSuccessUpdateAuthorityDetailData,
      };

    case SUCCESS_UPDATE_FOCUS_AREA:
      const successUpdateFocusAreaData = action.data;
      return { ...state, successUpdateFocusAreaData };
    case ERROR_UPDATE_FOCUS_AREA:
      const errorSuccessUpdateFocusAreaData = action.data;
      return {
        ...state,
        successUpdateFocusAreaData: errorSuccessUpdateFocusAreaData,
      };

    case SUCCESS_MY_DELETE_CARRIER:
      const successMyCarrierDeleteData = action.data;
      return { ...state, successMyCarrierDeleteData };
    case ERROR_MY_DELETE_CARRIER:
      const errorMyCarrierDelete = action.data;
      return { ...state, successMyCarrierDeleteData: errorMyCarrierDelete };

    case SUCCESS_MY_CARRIER_GET:
      const getMyCarrierData = action.data;
      return { ...state, getMyCarrierData };
    case ERROR_MY_CARRIER_GET:
      const errorgetMyCarrierData = action.data;
      return { ...state, getMyCarrierData: errorgetMyCarrierData };

    case SUCCESS_UPDATE_MY_CARRIER_DETAIL:
      const updateMyCarrierDetailData = action.data;
      return { ...state, updateMyCarrierDetailData };
    case ERROR_UPDATE_MY_CARRIER_DETAIL:
      const errorupdateMyCarrierDetailData = action.data;

      return {
        ...state,
        updateMyCarrierDetailData: errorupdateMyCarrierDetailData,
      };

    case SUCCESS_MY_CARRIER_ADD:
      const addMyCarrierDetailData = action.data;
      return { ...state, addMyCarrierDetailData };
    case ERROR_MY_CARRIER_ADD:
      const erroraddMyCarrierDetailData = action.data;
      return { ...state, addMyCarrierDetailData: erroraddMyCarrierDetailData };

    case SUCCESS_GET_MY_CARRIER_BY_ID:
      const mycarrierByIdData = action.data;
      return { ...state, mycarrierByIdData };
    case ERROR_GET_MY_CARRIER_BY_ID:
      const errormycarrierByIdData = action.data;
      return { ...state, mycarrierByIdData: errormycarrierByIdData };

    case SUCCESS_GET_MARKET_DATA_GRAPH:
      const marketDataGraphData = action.data;
      return { ...state, marketDataGraphData };
    case ERROR_GET_MARKET_DATA_GRAPH:
      const errorMarketDataGraphData = action.data;
      return { ...state, marketDataGraphData: errorMarketDataGraphData };

    case SUCCESS_GET_ADDITIONAL_MARKET_DATA:
      const additionalMarketData = action.data;
      return { ...state, additionalMarketData };
    case ERROR_GET_ADDITIONAL_MARKET_DATA:
      const erroradditionalMarketDataGraphData = action.data;
      return {
        ...state,
        additionalMarketData: erroradditionalMarketDataGraphData,
      };

    case SUCCESS_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL:
      const carrierSourcingSelectAllEmailDownloadExcelData = action.data;
      return { ...state, carrierSourcingSelectAllEmailDownloadExcelData };
    case ERROR_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL:
      const errorCarrierSourcingSelectAllEmailDownloadExcelData = action.data;
      return {
        ...state,
        carrierSourcingSelectAllEmailDownloadExcelData:
          errorCarrierSourcingSelectAllEmailDownloadExcelData,
      };

    case SUCCESS_HEADQUARTERS_SEARCH_LIST:
      const headquatersListData = action.data;
      return { ...state, headquatersListData };
    case ERROR_HEADQUARTERS_SEARCH_LIST:
      const errorHeadquatersListDataGraphData = action.data;
      return {
        ...state,
        headquatersListData: errorHeadquatersListDataGraphData,
      };

    case SUCCESS_ADVANCE_SEARCH_EQUIPMENT_LIST:
      let advanceSearchEquipmentData = action.data;
      return { ...state, advanceSearchEquipmentData };
    case ERROR_ADVANCE_SEARCH_EQUIPMENT_LIST:
      let errorAdvanceSearchEquipmentData = action.data;
      return {
        ...state,
        advanceSearchEquipmentData: errorAdvanceSearchEquipmentData,
      };

    case SUCCESS_ADVANCE_MARKET_EQUIPMENT_LIST:
      let advanceMarketEquipmentData = action.data;
      return { ...state, advanceMarketEquipmentData };
    case ERROR_ADVANCE_MARKET_EQUIPMENT_LIST:
      let errorAdvanceMarketEquipmentData = action.data;
      return {
        ...state,
        advanceMarketEquipmentData: errorAdvanceMarketEquipmentData,
      };

    case SUCCESS_DASHBOARD_DATA_SOURCE_LIST:
      let dashboardDataSorceListData = action.data;

      return { ...state, dashboardDataSorceListData };
    case ERROR_DASHBOARD_DATA_SOURCE_LIST:
      let errordashboardDataSorceListData = action.data;
      return {
        ...state,
        dashboardDataSorceListData: errordashboardDataSorceListData,
      };
    case SUCCESS_DASHBOARD_USER_LIST:
      let userListData = action.data;
      return { ...state, userListData };
    case ERROR_DASHBOARD_USER_LIST:
      let errorUserListData = action.data;
      return { ...state, userListData: errorUserListData };
    case SUCCESS_CARRIER_USER_LIST:
      let carrierUserListData = action.data;
      return { ...state, carrierUserListData };
    case ERROR_CARRIER_USER_LIST:
      let errorCarrierUserListData = action.data;
      return { ...state, carrierUserListData: errorCarrierUserListData };

    case SUCCESS_LANES_AND_MAP_LIST:
      const lanesAndMapData = action.data;
      return { ...state, lanesAndMapData };
    case ERROR_LANES_AND_MAP_LIST:
      const errorLanesAndMapData = action.data;
      return { ...state, lanesAndMapData: errorLanesAndMapData };

    case SUCCESS_NOTES_DELETE_DASHBOARD:
      let notesDeleteDashboardListData = action.data;

      return { ...state, notesDeleteDashboardListData };
    case ERROR_NOTES_DELETE_DASHBOARD:
      let errornotesDeleteDashboardListData = action.data;
      return {
        ...state,
        notesDeleteDashboardListData: errornotesDeleteDashboardListData,
      };

    case SUCCESS_HEADQUARTERS_SEARCH_VALUE_LIST:
      const headquartersSearchValueListData = action.data;
      return { ...state, headquartersSearchValueListData };
    case ERROR_HEADQUARTERS_SEARCH_VALUE_LIST:
      const errorheadquartersSearchValueListDataGraphData = action.data;
      return {
        ...state,
        headquartersSearchValueListData:
          errorheadquartersSearchValueListDataGraphData,
      };
    default:
      return state;
  }
};
