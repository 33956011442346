import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  appicon,
  avatarfile,
  brandlogo,
  clearUserDetails,
  EditOutlinedIcon,
  Empty,
  // errorNotification,
  Link,
  loader1,
  LOGIN,
  message,
  NOTIFICATIONS,
  NotificationsOutlinedIcon,
  PerfectScrollbar,
  PowerSettingsNewOutlinedIcon,
  staffgetpermission,
  Tokens,
  useDispatch,
  useHistory,
  //useIdleTimer,
  usePrevious,
  User,
  userLogout,
  useSelector,
  _,
  logoutTabclose,
  moment
} from "./index";
import { Popover } from "antd";

const Header = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userdata = User.getUserDetails();
  //const Tokensdata = Tokens.getToken();
  const [unreadCount, setUnreadCount] = useState(0);
  // const latestNotificationListData = useSelector(state => state.notifications.latestNotificationData);
  // const prevLatestNotificationListData = usePrevious({ latestNotificationListData });
 const [userData,setUserData]=useState("");
  const [loader, setLoader] = useState(false);
  let currentTime = new Date().valueOf();

  useEffect(() => {
    setLoader(true);
    props.setModuleVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    setUserData(props.userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userData]);
  // profile dropdown

  const handleprofilechangebutton = () => {
    history.push("settings");
    const userdetails = {
      user_id: userdata.id,
    };
    dispatch(staffgetpermission(userdetails));
  };

  const staffgetpermissionData = useSelector(
    (state) => state.managestaff.staffgetpermissionData
  );
  const prevstaffgetpermissionData = usePrevious({ staffgetpermissionData });
  const [avatarprofileimage, setavtarprofileImage] = useState("");
  const [notificationPermission, setNotificationPermission] = useState(false);
  const [latestNotificationsData, setLatestNotificationsData] = useState();

  useEffect(() => {
    if (
      prevstaffgetpermissionData &&
      prevstaffgetpermissionData.staffgetpermissionData !==
        staffgetpermissionData
    ) {
      if (
        staffgetpermissionData &&
        _.has(staffgetpermissionData, "data") &&
        staffgetpermissionData.success === true
      ) {
        setLoader(false);
        setLatestNotificationsData(staffgetpermissionData.data.notifications);
        setUnreadCount(staffgetpermissionData.data.unreadCount);
        if (
          staffgetpermissionData.data.profile_pic.includes(".png") === true ||
          staffgetpermissionData.data.profile_pic.includes(".jpeg") === true ||
          staffgetpermissionData.data.profile_pic.includes(".jpg") === true ||
          staffgetpermissionData.data.profile_pic.includes(".PNG") === true ||
          staffgetpermissionData.data.profile_pic.includes(".JPEG") === true ||
          staffgetpermissionData.data.profile_pic.includes(".JPG") === true
        ) {
          setavtarprofileImage(staffgetpermissionData.data.profile_pic);
        } else {
          setavtarprofileImage("");
        }

        if (
          staffgetpermissionData &&
          staffgetpermissionData.data &&
          staffgetpermissionData.data.user.user_role_id === 1
        ) {
          setNotificationPermission(true);
        } else {
          if (
            staffgetpermissionData &&
            staffgetpermissionData.data &&
            (staffgetpermissionData.data?.permissions[
              staffgetpermissionData.data.permissions.findIndex(
                (data) => data.module.module_name === "Notifications"
              )
            ].is_enabled === 1 ||
              staffgetpermissionData.data?.permissions[
                staffgetpermissionData.data.permissions.findIndex(
                  (data) => data.module.module_name === "Notifications"
                )
              ].is_enabled === "1")
          ) {
            setNotificationPermission(true);
          } else {
            setNotificationPermission(false);
          }
        }
      }
      if (
        staffgetpermissionData &&
        _.has(staffgetpermissionData, "message") &&
        staffgetpermissionData.success === false
      ) {
        setLoader(false);

        if (
          staffgetpermissionData &&
          _.has(staffgetpermissionData, "message") &&
          staffgetpermissionData.success === false
        ) {
          message.error(staffgetpermissionData.message);
        }
      }
    }
  }, [staffgetpermissionData, prevstaffgetpermissionData]);

  const userlogoutbutton = () => {
    clearUserDetails();
    dispatch(userLogout());
    setShowLogoutMessage(true);
  };

  const logoutData = useSelector((state) => state.auth.logoutData);
  const prevLogoutData = usePrevious({ logoutData });
  //const Tokensdata = Tokens.getToken();

  // const latestNotificationListData = useSelector(state => state.notifications.latestNotificationData);
  // const prevLatestNotificationListData = usePrevious({ latestNotificationListData });
  // Check The Logout User Data
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  useEffect(() => {
    if (showLogoutMessage) {
      message.success("User has been logged out");
    }
    if (prevLogoutData && prevLogoutData.logoutData !== logoutData) {
      if (logoutData.success === true || logoutData.success === false) {
        Tokens.removeLocalData();
        history.push(LOGIN);
        setShowLogoutMessage(false);
      }
      if (logoutData.success === false) {
      }
    }
  }, [logoutData, prevLogoutData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Notification list

  useEffect(() => {
    //use window.scrollY
    var scrollpos = window.scrollY;
    var header = document.getElementById("topNavbar");

    function add_class_on_scroll() {
      header.classList.add("sticky_navbar");
    }

    function remove_class_on_scroll() {
      header.classList.remove("sticky_navbar");
    }

    window.addEventListener("scroll", function () {
      //Here you forgot to update the value
      scrollpos = window.scrollY;

      if (scrollpos > 2) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  });

//   const handleOnIdle = (event) => {
//     // Tokens.removeLocalData();
//     // setTimeout(() => {
//     //   dispatch(userLogout());

//     //   errorNotification(
//     //     "Due to inactivity, we logged you out to protect your account. Please sign-in again."
//     //   );
//     // }, 2000);
//     const time=moment().format("h:mm:ss a");
//     console.log('system is idel now:'+time);
//   };

//  // eslint-disable-next-line
//   const { start } = useIdleTimer({
//     timeout: 1000 * 60 * 2,
//     onIdle: handleOnIdle,
//     debounce: 500,
//   });

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(logoutTabclose());
    }, 60000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);
  const updateTimeOutData = useSelector(
    (state) => state.help.logoutTabCloseData
  );
  const prevUpdateTimeOutData = usePrevious({ updateTimeOutData });
  useEffect(() => {
    if (
      prevUpdateTimeOutData &&
      prevUpdateTimeOutData.updateTimeOutData !== updateTimeOutData
    ) {
      if (updateTimeOutData && updateTimeOutData.success === true) {
      }
      if (
        updateTimeOutData &&
        _.has(updateTimeOutData, "message") &&
        updateTimeOutData.success === false
      ) {
        Tokens.removeLocalData();
        history.push(LOGIN);
      }
    }
    // eslint-disable-next-line
  }, [updateTimeOutData, prevUpdateTimeOutData]);
  return (
    <>
      <header className="page-topbar" id={currentTime}>
        <div id="topNavbar" className="navbar navbar-fixed">
          <nav className="navbar-main nav-collapsible sideNav-lock">
            <div className="nav-wrapper">
              <div className="brand-sidebar">
                <h1 className="logo-wrapper d-flex align-items-center position-relative">
                  <a className="navbar-toggler" href="#!">
                    <span className="hamburger_icon">
                      Sidebar Toggle Button
                    </span>
                  </a>
                  <a className="brand-logo" href="#!">
                    <img
                      className="hide-on-collapse"
                      src={brandlogo}
                      alt="logo"
                    />
                    <img
                      className="show-on-collapse hide-on-full"
                      src={appicon}
                      alt="logo"
                    />
                  </a>
                </h1>
              </div>

              {/* ul list start here */}
              <ul className="navbar-list ml-auto header-navbar-list nav-dropdowns">
                <li className="user-Name">
                  <Popover
                    overlayClassName="headerProfileDropdown"
                    placement="bottom"
                    content={
                      <ul>
                        {/* <button type="button" className="profile-button d-flex">
                          <span className="user-avatar avatar-status avatar-online">
                          <img
                          src={
                            avatarprofileimage ? avatarprofileimage : avatarfile
                          }
                          alt="avatar"
                          className="avtarstyle"
                        />
                          </span>
                          <span className="avatar-name mr-3">
                            <span className="text-truncate">
                              {userdata && userdata.username ? (
                                <span>{`${userdata.username.substring(
                                  0,
                                  4
                                )}... `}</span>
                              ) : (
                                ""
                              )}
                            </span>
                            <i className="ico_arrow"></i>
                          </span>
                        </button> */}
                        <li>
                          <button
                            className="profilechnageeditor"
                            onClick={handleprofilechangebutton}
                          >
                            <span className="profilechangetext">
                              Edit Profile
                            </span>
                            <EditOutlinedIcon />
                          </button>
                        </li>

                        <li>
                          <button
                            className="text-danger"
                            onClick={() => userlogoutbutton()}
                          >
                            Logout{" "}
                            <PowerSettingsNewOutlinedIcon className="fw-bold" />
                          </button>
                        </li>
                      </ul>
                    }
                  >
                    <span className="user-avatar avatar-status avatar-online">
                      <img
                        src={
                          avatarprofileimage ? avatarprofileimage : avatarfile
                        }
                        alt="avatar"
                        className="avtarstyle"
                      />
                    </span>
                    <span className="avatar-name mr-3">
                      <span className="text-truncate">
                        {userData && userData.username ? (
                          <span>{`${userData.username.toString().substring(
                            0,
                            4
                          )}... `}</span>
                        ) : (
                          ""
                        )}
                      </span>
                      <i className="ico_arrow"></i>
                    </span>
                  </Popover>
                </li>

                {/* Notification list  */}

                <li className="notification_col">
                  <Popover
                    overlayClassName="headerNotificationDropdown"
                    placement="bottom"
                    content={
                      <div className="notiDropdown_content">
                        <div className="titleheader d-flex w-100"></div>
                        <PerfectScrollbar className="notificationScroll">
                          {notificationPermission &&
                          latestNotificationsData &&
                          latestNotificationsData.length > 0 ? (
                            latestNotificationsData.map(
                              (notification, index) => {
                                return (
                                  <Dropdown.Item key={index + 1}>
                                    {/* eslint-disable-next-line */}
                                    {/* <a className="black-text" target="_blank"> */}
                                    <span className="status_dot unread"></span>
                                    {notification.notification_message}
                                    <time
                                      className="media-meta grey-text darken-2"
                                      dateTime="2015-06-12T20:50:48+08:00"
                                    >
                                      {notification.time}
                                    </time>
                                    {/* </a> */}
                                  </Dropdown.Item>
                                );
                              }
                            )
                          ) : (
                            <div className="col-12 col-lg-12">
                              <div className="templetEle d-flex justify-content-center">
                                <div>
                                  <Empty />
                                </div>
                              </div>
                            </div>
                          )}
                        </PerfectScrollbar>
                        {notificationPermission &&
                          latestNotificationsData &&
                          latestNotificationsData.length > 4 && (
                            <div className="dropFooter d-flex">
                              <Link
                                to={NOTIFICATIONS}
                                className="btn btn-default viewmore"
                              >
                                <span>View all notifications</span>
                              </Link>
                            </div>
                          )}
                      </div>
                    }
                  >
                    <i
                      className={
                        history.location.pathname === "/notifications"
                          ? "text-danger notification-icons"
                          : "bold notification-icons"
                      }
                    >
                      {/* <img
                src={notificationimage}
                alt="notification-icon"
                /> */}

                      <NotificationsOutlinedIcon className="notification-icons-image" />
                      {notificationPermission && unreadCount > 0 ? (
                        latestNotificationsData &&
                        latestNotificationsData.length > 0 ? (
                          <small className="notification-badge"></small>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </i>
                  </Popover>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      {loader ? (
        <div className="loader">
          <img
            src={loader1}
            alt="Loading..."
            className="loading_img"
            width="100px"
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
