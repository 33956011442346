import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation ,useHistory} from "react-router-dom";
import { isLoggedIn } from "./authServices";
import Sidebar from "../view/component/dashboard/sidebar";
import Header from "../view/component/dashboard/header";
import loader1 from "../assets/images/loader1.svg";
// Import Route Constant

import {
  LOGIN,
  REGISTER,
  DASHBOARD,
  ABOUT,
  MANAGESTAFFACCESS,
  SETTINGS,
  MANAGEMAILS,
  FORGOTPASSWORD,
  RESETPASSWORD,
  VERIFY_EMAIL,
  NOTIFICATIONS,
  HELP,
  EMAIL_VERIFICATION,
  CARRIERDETAIL,
  STAFFPERMISSIONID,
  METRICS,
  RELOADCARRIER,
  NEWCARRIERDATA,
  MARKETDATA,
  POSTLOADDASHBOARD,
  CARRIERPORTAL,
  RFPUPLOAD,
  RFPREPORT,
  MANAGESUPPORTEMAILS,
  MOBILE_VERIFICATION,
  MANAGEUNSUBSCRIBEEMAILS
} from "./routeContants";
import { usePrevious } from "../common/custom";
import { Tokens } from "../../app/storage/index";
import _ from "lodash";
import { useSelector } from "react-redux";
 import { io } from "socket.io-client";
export const UploadMain = React.lazy(() =>
  import("../view/screen/RFP/RFPUpload/uploadMain")
);
export const ReportMain = React.lazy(() =>
  import("../view/screen/RFP/RFPReport/reportMain")
);

// import { User } from '../storage';

// Import Component
export const Marketdata = React.lazy(() =>
  import("../view/screen/MarketData/Marketdata")
);
export const Login = React.lazy(() => import("../view/screen/auth/login"));
export const Register = React.lazy(() =>
  import("../view/screen/auth/register")
);
export const Dashboard = React.lazy(() =>
  import("../view/screen/dashboard/dashboard")
);
export const UserDetails = React.lazy(() =>
  import("../view/screen/details/Details")
);
export const ForgotPassword = React.lazy(() =>
  import("../view/screen/auth/forgotResetPassword")
);
export const ResetPassword = React.lazy(() =>
  import("../view/screen/auth/resetPassword")
);
export const VerifyEmail = React.lazy(() =>
  import("../view/screen/auth/verifyEmail")
);
export const EmailVerification = React.lazy(() =>
  import("../view/screen/auth/EmailVerification")
);
export const ManageStaffAccess = React.lazy(() =>
  import("../view/screen/managaStaffAccess/manageStaffAccess")
);
export const Settings = React.lazy(() =>
  import("../view/screen/settings/settings")
);
export const About = React.lazy(() => import("../view/screen/about/about"));
export const ManageEmails = React.lazy(() =>
  import("../view/screen/manageEmails/manage-emails")
);
export const ManageSupportEmails = React.lazy(() =>
  import("../view/screen/manageSupportEmails/manage-support-emails")
);
export const Notifications = React.lazy(() =>
  import("../view/screen/notification/notifications")
);
export const Help = React.lazy(() => import("../view/screen/help/help"));
export const Empty = React.lazy(() => import("../view/screen/empty/empty"));
export const Error = React.lazy(() => import("../common/Error"));
export const Metrics = React.lazy(() =>
  import("../view/screen/metricsdetails/metrics")
);
export const StaffPermission = React.lazy(() =>
  import("../view/screen/managaStaffAccess/staffPermission")
);
export const ReloadCarrier = React.lazy(() =>
  import("../view/screen/reloadcarrier/ReloadCarrier")
);
export const AddNewCarrier = React.lazy(() =>
  import("../view/screen/addnewcarrier/AddNewCarrier")
);
export const CarrierPortal = React.lazy(() =>
  import("../view/screen/carrierPortal/carrierPortal")
);
export const PostingLoadDashboard = React.lazy(() =>
  import("../view/screen/postingLoad/dashboard/PostingLoadDashboard")
);
export const MobileVerification = React.lazy(() =>
  import("../view/screen/auth/MobileVerification")
);
export const ManageUnsubscribeEmails = React.lazy(() =>
  import("../view/screen/manageUnsubscribeEmails/manage-unsubscribe-emails")
);
const PrivateRoute = ({ component: Component,userData, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? (
        props.location.pathname === '/settings'? <Component {...props} userData={userData}/> :<Component {...props}/> 
      ) : (
        <Redirect
          to={{
            pathname: LOGIN,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isLoggedIn() || isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: DASHBOARD,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: DASHBOARD,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);


const AllRoutes = () => {
  const location = useLocation();
  const history = useHistory();
  if (location.pathname === "/dashboard") {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      // history.go(1);
    };
  }
   const [socket, setSocket] = useState(null);
  const [userData, setUserData] = useState();
  // const userDataStatic = User.getUserDetails();
  const staffgetpermissionData = useSelector(
    (state) => state.managestaff.staffgetpermissionData
  );
  const prevstaffgetpermissionData = usePrevious({ staffgetpermissionData });
  const [staffpermission, setstaffpermission] = useState("");
  const [moduleVisible, setModuleVisible] = useState(false);
  useEffect(() => {
    if (
      prevstaffgetpermissionData &&
      prevstaffgetpermissionData.staffgetpermissionData !==
      staffgetpermissionData
    ) {
      if (
        staffgetpermissionData &&
        _.has(staffgetpermissionData, "data") &&
        staffgetpermissionData.success === true
      ) {
        setstaffpermission(staffgetpermissionData.data.permissions);
        setModuleVisible(true);
        setUserData(staffgetpermissionData.data.user);
      }
    }
  }, [staffgetpermissionData, prevstaffgetpermissionData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(()=>{
    const socketInstance = io('https://events.sorentransport.com');
    setSocket(socketInstance);
    //dynmic id
    if(userData){
      socketInstance.on("user_listen_"+(userData && userData.id),function (data) {
        if(data == 'refresh it'){
          Tokens.removeLocalData();
          history.push(LOGIN);
        }
    } );
  }
  },[userData]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return !isLoggedIn() ? (
            <Redirect to={LOGIN} />
          ) : (
            <Redirect to={DASHBOARD} />
          );
        }}
      />

      <LoginRoute exact key="login" path={LOGIN} component={Login} />
      <PublicRoute exact path={REGISTER} component={Register} />
      <PublicRoute exact path={FORGOTPASSWORD} component={ForgotPassword} />
      <PublicRoute path={RESETPASSWORD} component={ResetPassword} />
      <PublicRoute exact path={VERIFY_EMAIL} component={VerifyEmail} />
      <PublicRoute exact path={CARRIERPORTAL} component={CarrierPortal} />

      <PublicRoute
        exact
        path={EMAIL_VERIFICATION}
        component={EmailVerification}
      />
      <PublicRoute
        exact
        path={MOBILE_VERIFICATION}
        component={MobileVerification}
      />

      <Suspense
        fallback={
          <div className="loader">
            <img
              src={loader1}
              alt="Loading..."
              className="loading_img"
              width="100px"
            />
          </div>
        }
      >
        <Route>
          <div className="appMain_body dashPage_body vertical-layout vertical-menu-collapsible page-header-dark vertical-modern-menu 2-columns">
            <Header
              moduleVisible={moduleVisible}
              setModuleVisible={setModuleVisible}
              userData={userData}
            />
            <Sidebar userData={userData} staffpermission={staffpermission}/>

            {userData && userData.user_role_id !== 1 ? (
              <div id="main" className="dash_main_container ">
                {/* posting load*/}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Post Loads"
                    )
                  ]?.module?.module_name === "Post Loads" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Post Loads"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="posting-load"
                        path={POSTLOADDASHBOARD}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="posting-load"
                        path={POSTLOADDASHBOARD}
                        component={PostingLoadDashboard}
                      />
                    </span>
                  )}
                {/* dashboard */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ]?.module?.module_name === "Dashboard" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="error"
                        path={DASHBOARD}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="dashboard"
                        path={DASHBOARD}
                        component={Dashboard}
                      />
                    </span>
                  )}
                {/* Market data */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ]?.module?.module_name === "Dashboard" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="Marketdata"
                        path={MARKETDATA}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="Marketdata"
                        path={MARKETDATA}
                        component={Marketdata}
                      />
                    </span>
                  )}
                {/* manage staff access */}
                {(userData && userData.user_role_id !== 1 &&  userData.is_manager !==1) || (staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Staff"
                    )
                  ]?.module?.module_name === "Manage Staff" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Staff"
                    )
                  ].is_enabled === 0)
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-staff-access"
                        path={MANAGESTAFFACCESS}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-staff-access"
                        path={MANAGESTAFFACCESS}
                        component={ManageStaffAccess}
                      />
                    </span>
                  )}
                {/* staff permission  */}
                {userData && userData.user_role_id !== 1 &&  userData.is_manager!==1 
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="staffpermission"
                        path={STAFFPERMISSIONID}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="staffpermission"
                        path={STAFFPERMISSIONID}
                        component={StaffPermission}
                      />
                    </span>
                  )}
                {/* Manage Emails */}
                {/* {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Emails"
                    )
                  ]?.module?.module_name === "Manage Emails" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Emails"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-emails"
                        path={MANAGEMAILS}
                        component={Error}
                      />

                     <PrivateRoute
                        exact
                        key="manage-unsubscribe-emails"
                        path={MANAGEUNSUBSCRIBEEMAILS}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-emails"
                        path={MANAGEMAILS}
                        component={ManageEmails}
                      />
                      
                      <PrivateRoute
                        exact
                        key="manage-unsubscribe-emails"
                        path={MANAGEUNSUBSCRIBEEMAILS}
                        component={ManageUnsubscribeEmails}
                      />
                    </span>
                  )} */}
                  
                   {/* Manage Emails */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name=== "Emails"
                    )
                  ]?.module?.module_name === "Emails" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Emails" 
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-emails"
                        path={MANAGEMAILS}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-emails"
                        path={MANAGEMAILS}
                        component={ManageEmails}
                      />
                      
                    </span>
                  )}
                   {/* Manage Emails */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Unsubscribe Emails"
                    )
                  ]?.module?.module_name === "Unsubscribe Emails" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Unsubscribe Emails"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                     <PrivateRoute
                        exact
                        key="manage-unsubscribe-emails"
                        path={MANAGEUNSUBSCRIBEEMAILS}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-unsubscribe-emails"
                        path={MANAGEUNSUBSCRIBEEMAILS}
                        component={ManageUnsubscribeEmails}
                      />
                    </span>
                  )}
                  {/* Manage Support Emails */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Support Emails"
                    )
                  ]?.module?.module_name === "Manage Support Emails" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Support Emails"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-support-emails"
                        path={MANAGESUPPORTEMAILS}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="manage-support-emails"
                        path={MANAGESUPPORTEMAILS}
                        component={ManageSupportEmails}
                      />
                    </span>
                  )}
                {/* settings */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Settings"
                    )
                  ]?.module?.module_name === "Settings" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Settings"
                    )
                  ].is_enabled === 0 ? (
                  <span>
                    <PrivateRoute
                      exact
                      key="settings"
                      path={SETTINGS}
                      component={Error}
                    />
                  </span>
                ) : (
                  moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="settings"
                        path={SETTINGS}
                        component={Settings}
                        userData={userData}
                      />
                    </span>
                  )
                )}
                {/* help */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Help"
                    )
                  ]?.module?.module_name === "Help" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Help"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="help"
                        path={HELP}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="help"
                        path={HELP}
                        component={Help}
                      />
                    </span>
                  )}
                {/* Metrics */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Metrics"
                    )
                  ]?.module?.module_name === "Metrics" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Metrics"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="metrics"
                        path={METRICS}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="metrics"
                        path={METRICS}
                        component={Metrics}
                      />
                    </span>
                  )}
                {/* Carrier Details */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ]?.module?.module_name === "Dashboard" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="details"
                        path={CARRIERDETAIL}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="details"
                        path={CARRIERDETAIL}
                        component={UserDetails}
                      />
                    </span>
                  )}
                {/* Market data */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ]?.module?.module_name === "Dashboard" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Dashboard"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="Marketdata"
                        path={MARKETDATA}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="Marketdata"
                        path={MARKETDATA}
                        component={Marketdata}
                      />
                    </span>
                  )}
                {/* Notifications */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Notifications"
                    )
                  ]?.module?.module_name === "Notifications" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Notifications"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="notificationDetails"
                        path={NOTIFICATIONS}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="notificationDetails"
                        path={NOTIFICATIONS}
                        component={Notifications}
                      />
                    </span>
                  )}
                {/* Reload Carrier Report */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Reload Carrier"
                    )
                  ]?.module?.module_name === "Reload Carrier" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Reload Carrier"
                    )
                  ]?.is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="reload-carrier-report"
                        path={RELOADCARRIER}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="reload-carrier-report"
                        path={RELOADCARRIER}
                        component={ReloadCarrier}
                      />
                    </span>
                  )}
                {/* New Carrier Data */}
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Insert Carrier Data"
                    )
                  ]?.module?.module_name === "Insert Carrier Data" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Insert Carrier Data"
                    )
                  ].is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="add-carrier-data"
                        path={NEWCARRIERDATA}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="add-carrier-data"
                        path={NEWCARRIERDATA}
                        component={AddNewCarrier}
                        userData={userData}
                      />
                    </span>
                  )}

                <PrivateRoute
                  exact
                  key="about"
                  path={ABOUT}
                  component={About}
                />
                {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Upload"
                    )
                  ]?.module?.module_name === "Upload" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Upload"
                    )
                  ]?.is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      
                      <PrivateRoute
                        exact
                        key="rfp_upload"
                        path={RFPUPLOAD}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="rfp_upload"
                        path={RFPUPLOAD}
                        component={UploadMain}
                      />
                     
                    </span>
                  )}
                  {staffpermission &&
                  staffpermission.length > 0 &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Report"
                    )
                  ]?.module?.module_name === "Report" &&
                  staffpermission[
                    staffpermission.findIndex(
                      (data) => data.module.module_name === "Report"
                    )
                  ]?.is_enabled === 0
                  ? moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="rfp_report"
                        path={RFPREPORT}
                        component={Error}
                      />
                    </span>
                  )
                  : moduleVisible && (
                    <span>
                      <PrivateRoute
                        exact
                        key="rfp_report"
                        path={RFPREPORT}
                        component={ReportMain}
                      />
                    </span>
                  )}
                <PrivateRoute exact key="empty" path="*" component={Empty} />
              </div>
            ) : (
              <div id="main" className="dash_main_container ">
                <PrivateRoute
                  exact
                  key="dashboard"
                  path={DASHBOARD}
                  component={Dashboard}
                />
                <PrivateRoute
                  exact
                  key="Marketdata"
                  path={MARKETDATA}
                  component={Marketdata}
                />
                <PrivateRoute
                  exact
                  key="manage-staff-access"
                  path={MANAGESTAFFACCESS}
                  component={ManageStaffAccess}
                />
                <PrivateRoute
                  exact
                  key="staffpermission"
                  path={STAFFPERMISSIONID}
                  component={StaffPermission}
                />
                <PrivateRoute
                  exact
                  key="manage-emails"
                  path={MANAGEMAILS}
                  component={ManageEmails}
                />
                <PrivateRoute
                        exact
                        key="manage-support-emails"
                        path={MANAGESUPPORTEMAILS}
                        component={ManageSupportEmails}
                      />
                <PrivateRoute
                  exact
                  key="settings"
                  path={SETTINGS}
                  component={Settings}
                  userData={userData}
                />
                <PrivateRoute exact key="help" path={HELP} component={Help} />
                <PrivateRoute
                  exact
                  key="metrics"
                  path={METRICS}
                  component={Metrics}
                />
                <PrivateRoute
                  exact
                  key="details"
                  path={CARRIERDETAIL}
                  component={UserDetails}
                />
                <PrivateRoute
                  exact
                  key="notificationDetails"
                  path={NOTIFICATIONS}
                  component={Notifications}
                />
                <PrivateRoute
                  exact
                  key="reload-carrier-report"
                  path={RELOADCARRIER}
                  component={ReloadCarrier}
                />
                <PrivateRoute
                  exact
                  key="add-carrier-data"
                  path={NEWCARRIERDATA}
                  component={AddNewCarrier}
                />
                <PrivateRoute
                  exact
                  key="posting-load"
                  path={POSTLOADDASHBOARD}
                  component={PostingLoadDashboard}
                />
                <PrivateRoute
                  exact
                  key="rfp_upload"
                  path={RFPUPLOAD}
                  component={UploadMain}
                />
                <PrivateRoute
                  exact
                  key="rfp_report"
                  path={RFPREPORT}
                  component={ReportMain}
                />
                <PrivateRoute
                exact
                key="manage-unsubscribe-emails"
                path={MANAGEUNSUBSCRIBEEMAILS}
                component={ManageUnsubscribeEmails}
                      />
              </div>
            )}

            <div id="main" className="dash_main_container ">
              <PrivateRoute exact key="empty" path="*" component={Empty} />
            </div>
          </div>
          {/* <Redirect from="/*" to={DASHBOARD} /> */}
          {/* <Route path="*" component={Dashboard} /> */}
        </Route>
      </Suspense>
      {/* <Redirect from="/*" to={LOGIN} /> */}
    </Switch>
  );
};

export default AllRoutes;
