import {
  ERROR_CHANGED_NOTIFICATION_STATUS,
  ERROR_CHANGE_PASSWORD_SETTINGS,
  ERROR_CREATE_NOTIFICATION_ALERT,
  ERROR_DESTINATION_LIST,
  ERROR_NOTIFICATION_LIST,
  ERROR_ORIGINS_LIST,
  ERROR_SAVE_PREFERENCE,
  SUCCESS_CHANGED_NOTIFICATION_STATUS,
  SUCCESS_CHANGE_PASSWORD_SETTINGS,
  SUCCESS_CREATE_NOTIFICATION_ALERT,
  SUCCESS_DESTINATION_LIST,
  SUCCESS_NOTIFICATION_LIST,
  SUCCESS_ORIGINS_LIST,
  SUCCESS_SAVE_PREFERENCE,
  SUCCESS_DELETE_NOTIFICATION,
  ERROR_DELETE_NOTIFICATION,
  SUCCESS_EDIT_NOTIFICATION,
  ERROR_EDIT_NOTIFICATION,
  SUCCESS_ORIGIN_DESTINATION_DATA,
  ERROR_ORIGIN_DESTINATION_DATA,
  SUCCESS_SYNC_NOTIFICATION_DATA,
  ERROR_SYNC_NOTIFICATION_DATA,
  SUCCESS_INSERT_SIGNATURE,
  ERROR_INSERT_SIGNATURE,
  SUCCESS_GET_SIGNATURE,
  ERROR_GET_SIGNATURE,
  SUCCESS_Change_Profile_Pic,
  ERROR_Change_Profile_Pic,
  SUCCESS_SETTING_EQUIPMENT_TYPE_LIST,
  ERROR_SETTING_EQUIPMENT_TYPE_LIST,
  SUCCESS_SETTING_DB_LIMIT,
  ERROR_SETTING_DB_LIMIT,
  SUCCESS_EDIT_SIGNATURE,
  ERROR_EDIT_SIGNATURE,
  SUCCESS_DELETE_SIGNATURE,
  ERROR_DELETE_SIGNATURE,
  SUCCESS_LIST_SIGNATURE,
  ERROR_LIST_SIGNATURE,
  SUCCESS_ALL_LIST_SIGNATURE,
  ERROR_ALL_LIST_SIGNATURE,
  SUCCESS_CHECK_SIGNATURE_NAME,
  ERROR_CHECK_SIGNATURE_NAME,
  SUCCESS_SETTING_RFP_ALERT_STATUS,
  ERROR_SETTING_RFP_ALERT_STATUS,
  SUCCESS_SETTING_RFP_ALERT_UPDATE,
  ERROR_SETTING_RFP_ALERT_UPDATE
} from "./settings.actions";
import { DEFAULT_STATE } from "./settings.state";

export const settingsReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_CHANGE_PASSWORD_SETTINGS:
      const changePasswordSettingsData = action.data;
      return { ...state, changePasswordSettingsData };
    case ERROR_CHANGE_PASSWORD_SETTINGS:
      const errorChangePasswordSettingsData = action.data;
      return {
        ...state,
        changePasswordSettingsData: errorChangePasswordSettingsData,
      };

    case SUCCESS_NOTIFICATION_LIST:
      const notificationListData = action.data;
      return { ...state, notificationListData };
    case ERROR_NOTIFICATION_LIST:
      const errorNotificationListData = action.data;
      return { ...state, notificationListData: errorNotificationListData };

    case SUCCESS_CHANGED_NOTIFICATION_STATUS:
      const changedNotificationStatusData = action.data;
      return { ...state, changedNotificationStatusData };
    case ERROR_CHANGED_NOTIFICATION_STATUS:
      const errorchangedNotificationStatusData = action.data;
      return {
        ...state,
        changedNotificationStatusData: errorchangedNotificationStatusData,
      };

    case SUCCESS_DESTINATION_LIST:
      const destinationsListData = action.data;
      return { ...state, destinationsListData };
    case ERROR_DESTINATION_LIST:
      const errorDestinationsListData = action.data;
      return { ...state, destinationsListData: errorDestinationsListData };

    case SUCCESS_ORIGINS_LIST:
      const originsListData = action.data;
      return { ...state, originsListData };
    case ERROR_ORIGINS_LIST:
      const errorOriginsListData = action.data;
      return { ...state, originsListData: errorOriginsListData };

    case SUCCESS_CREATE_NOTIFICATION_ALERT:
      const createNotificationAlertData = action.data;
      return { ...state, createNotificationAlertData };
    case ERROR_CREATE_NOTIFICATION_ALERT:
      const errorCreateNotificationAlertData = action.data;
      return {
        ...state,
        createNotificationAlertData: errorCreateNotificationAlertData,
      };

    case SUCCESS_SAVE_PREFERENCE:
      const savePreferenceData = action.data;
      return { ...state, savePreferenceData };
    case ERROR_SAVE_PREFERENCE:
      const errorsavePreferenceData = action.data;
      return { ...state, savePreferenceData: errorsavePreferenceData };

    case SUCCESS_DELETE_NOTIFICATION:
      const deleteNotificationData = action.data;
      return { ...state, deleteNotificationData };
    case ERROR_DELETE_NOTIFICATION:
      const errorDeleteNotificationData = action.data;
      return { ...state, deleteNotificationData: errorDeleteNotificationData };

    case SUCCESS_EDIT_NOTIFICATION:
      const editNotificationData = action.data;
      return { ...state, editNotificationData };
    case ERROR_EDIT_NOTIFICATION:
      const errorEditNotificationData = action.data;
      return { ...state, editNotificationData: errorEditNotificationData };

    case SUCCESS_ORIGIN_DESTINATION_DATA:
      const originDestinationData = action.data;
      return { ...state, originDestinationData };
    case ERROR_ORIGIN_DESTINATION_DATA:
      const errorOriginDestinationData = action.data;
      return { ...state, originDestinationData: errorOriginDestinationData };

    case SUCCESS_SYNC_NOTIFICATION_DATA:
      const syncNotificationData = action.data;
      return { ...state, syncNotificationData };
    case ERROR_SYNC_NOTIFICATION_DATA:
      const errorSyncNotificationData = action.data;
      return { ...state, syncNotificationData: errorSyncNotificationData };

    case SUCCESS_INSERT_SIGNATURE:
      const addSignatureData = action.data;
      return { ...state, addSignatureData };
    case ERROR_INSERT_SIGNATURE:
      const erroraddSignatureData = action.data;
      return { ...state, addSignatureData: erroraddSignatureData };
    case SUCCESS_GET_SIGNATURE:
      const getSignatureData = action.data;
      return { ...state, getSignatureData };
    case ERROR_GET_SIGNATURE:
      const errorGetSignatureData = action.data;
      return { ...state, getSignatureData: errorGetSignatureData };

    case SUCCESS_Change_Profile_Pic:
      const changeProfilePicData = action.data;
      return { ...state, changeProfilePicData };
    case ERROR_Change_Profile_Pic:
      const errorchangeProfilePicData = action.data;
      return { ...state, changeProfilePicData: errorchangeProfilePicData };

    case SUCCESS_SETTING_EQUIPMENT_TYPE_LIST:
      let equipmentTypeData = action.data
      return { ...state, equipmentTypeData }
    case ERROR_SETTING_EQUIPMENT_TYPE_LIST:
      let errorEquipmentTypeData = action.data
      return { ...state, equipmentTypeData: errorEquipmentTypeData }

    case SUCCESS_SETTING_DB_LIMIT:
      const dbLimitData = action.data
      return { ...state, dbLimitData }
    case ERROR_SETTING_DB_LIMIT:
      const errorDbLimitData = action.data
      return { ...state, dbLimitData: errorDbLimitData }

    case SUCCESS_EDIT_SIGNATURE:
      const editSignatureData = action.data
      return { ...state, editSignatureData }
    case ERROR_EDIT_SIGNATURE:
      const errorEditSignatureData = action.data
      return { ...state, editSignatureData: errorEditSignatureData }

    case SUCCESS_DELETE_SIGNATURE:
      const deleteSignature = action.data
      return { ...state, deleteSignature }
    case ERROR_DELETE_SIGNATURE:
      const errorDeleteSignature= action.data
      return { ...state, deleteSignature: errorDeleteSignature }

      case SUCCESS_LIST_SIGNATURE:
        const listSignatureData = action.data
        return { ...state, listSignatureData }
      case ERROR_LIST_SIGNATURE:
        const errorListSignatureData = action.data
        return { ...state, listSignatureData: errorListSignatureData }


    case SUCCESS_ALL_LIST_SIGNATURE:
      const allListSignature = action.data
      return { ...state, allListSignature }
    case ERROR_ALL_LIST_SIGNATURE:
      const errorAllListSignature = action.data
      return { ...state, allListSignature: errorAllListSignature }

    case SUCCESS_CHECK_SIGNATURE_NAME:
        const checkSignatureName = action.data
        return { ...state, checkSignatureName }
    case ERROR_CHECK_SIGNATURE_NAME:
        const errorCheckSignatureName = action.data
        return { ...state, checkSignatureName: errorCheckSignatureName }
    
    case SUCCESS_SETTING_RFP_ALERT_STATUS:
          const rfpAlertStatusData = action.data
          return { ...state, rfpAlertStatusData }
    case ERROR_SETTING_RFP_ALERT_STATUS:
          const errorRfpAlertStatusData = action.data
          return { ...state, checkSignatureName: errorRfpAlertStatusData }
    
    case SUCCESS_SETTING_RFP_ALERT_UPDATE:
            const rfpAlertUpdateData = action.data
            return { ...state, rfpAlertUpdateData }
    case ERROR_SETTING_RFP_ALERT_UPDATE:
            const errorRfpAlertUpdateData = action.data
            return { ...state, rfpAlertUpdateData: errorRfpAlertUpdateData }

    default:
      return state;
  }
};
