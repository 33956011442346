import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import {
  reloadcarrierApi,
  reloadDataSourceApi,
  reloadEquipmentTypeListApi,
  reloadreportdownloadApi,
} from "../../../api/sdk/reloadcarrier";
import {
  ERROR_RELOAD_CARRIER,
  ERROR_RELOAD_DATASOURCE_LIST,
  ERROR_RELOAD_EQUIPMENT_TYPE_LIST,
  ERROR_RELOAD_REPORT_DOWNLOAD,
  ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  reloadCarrierListResponse,
  reloadDataSourceListResponse,
  reloadEquipmentTypeListResponse,
  reloadreportdownloadListResponse,
  RELOAD_CARRIER,
  RELOAD_DATASOURCE_LIST,
  RELOAD_EQUIPMENT_TYPE_LIST,
  RELOAD_REPORT_DOWNLOAD,
  selectAllEmailReloadreportdownloadListResponse,
  SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_RELOAD_CARRIER,
  SUCCESS_RELOAD_DATASOURCE_LIST,
  SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST,
  SUCCESS_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
} from "./reloadcarrier.action";

function* reloadcarrierRequest(data) {
  let getData = yield reloadcarrierApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(reloadCarrierListResponse(SUCCESS_RELOAD_CARRIER, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(reloadCarrierListResponse(ERROR_RELOAD_CARRIER, getData.data));
    // message.error(getData.data.message)
  }
}
export function* reloadcarrierWatcher() {
  yield takeLatest(RELOAD_CARRIER, reloadcarrierRequest);
}

function* reloadreportdownloadRequest(data) {
  let getData = yield reloadreportdownloadApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      reloadreportdownloadListResponse(
        SUCCESS_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      reloadreportdownloadListResponse(
        ERROR_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* reloadreportdownloadWatcher() {
  yield takeLatest(RELOAD_REPORT_DOWNLOAD, reloadreportdownloadRequest);
}



function* selectAllEmailReloadreportdownloadRequest(data) {
  let getData = yield reloadreportdownloadApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      selectAllEmailReloadreportdownloadListResponse(
        SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      selectAllEmailReloadreportdownloadListResponse(
        ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* selectAllEmailReloadreportdownloadWatcher() {
  yield takeLatest(SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD, selectAllEmailReloadreportdownloadRequest);
}

function* reloadEquipmentTypeList(data) {
  let reloadEquipmentList = yield reloadEquipmentTypeListApi(data)
  if (reloadEquipmentList.success && _.has(reloadEquipmentList, "data.data")) {
    yield put(reloadEquipmentTypeListResponse(
      SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST,
      reloadEquipmentList.data
    ))
  } else {
    yield put(reloadEquipmentTypeListResponse(
      ERROR_RELOAD_EQUIPMENT_TYPE_LIST,
      reloadEquipmentList.data
    ))
  }
}
export function* reloadEquipmentTypeListWatcher() {
  yield takeLatest(
    RELOAD_EQUIPMENT_TYPE_LIST,
    reloadEquipmentTypeList
  )
}
// reload carrier data source

function* reloadDataSource(data) {
  let reloadDataSourceData = yield (reloadDataSourceApi(data))
  if (reloadDataSourceData.sucess && _.has(reloadDataSourceData, "data.data")) {
    yield put(reloadDataSourceListResponse(
      SUCCESS_RELOAD_DATASOURCE_LIST, reloadDataSourceData.data
    ))
  } else {
    yield put(reloadDataSourceListResponse(
      ERROR_RELOAD_DATASOURCE_LIST, reloadDataSourceData.data
    ))
  }
}
export function* reloadDataSourceWatcher() {
  yield takeLatest(RELOAD_DATASOURCE_LIST, reloadDataSource)
}