import { request } from "../request/axios.request";
import {
  DASHBOARD_FILTER_TRACKING,
  DOWNLOAD_METRICS_DATA_EXCEL,
  METRICS_USER_GRAPH,
  // TRACK_LOGIN_USER,
  USERS_LIST,
  DOWNLOAD_SEARCH_FILTER_DATA_EXCEL_API,
} from "../routing/route";

// export async function trackloginuserApi(data) {
//   let trackloginuserdata = data.data;
//   return request({
//     url: TRACK_LOGIN_USER,
//     method: 'get',
//     params: trackloginuserdata,
//   });
// }
export async function dashboardfiltertrackingApi(data) {
  let dashboardfiltertrackingData = data.data;
  return request({
    url: DASHBOARD_FILTER_TRACKING,
    method: "get",
    params: dashboardfiltertrackingData,
  });
}
export async function userListApi(data) {
  let searchData = data.data;
  return request({ url: USERS_LIST, method: "get", params: searchData });
}

//DOWNLOAD_METRICS_DATA_EXCEL
export async function downloadMetricsDataExcelApi(data) {
  let downloadMetricsDataExcelData = data.data;
  return request({
    url: DOWNLOAD_METRICS_DATA_EXCEL,
    method: "get",
    params: downloadMetricsDataExcelData,
  });
}

//DOWNLOAD_EMAIL_FILTER_DATA_EXCEL
export async function downloadSearchFilterDataExcelApi(data) {
  let emailFilterParams = data.data;
  return request({
    url: DOWNLOAD_SEARCH_FILTER_DATA_EXCEL_API,
    method: "post",
    params: emailFilterParams,
  });
}


// METRICS USER GRAPH
export async function metricsTypeGraphApi(data) {
  let metricsGraph2Data = data.data;
  return request({
    url: METRICS_USER_GRAPH,
    params: metricsGraph2Data,
    method: "get",
  });
}
