import { all } from "redux-saga/effects";
import {
  loginWatcher,
  registrationWatcher,
  forgotPasswordWatcher,
  resetPasswordWatcher,
  verifyEmailWatcher,
  logoutWatcher,
  emailVerificationWatcher,
  linkexpireWatcher,
  verifyUserWatcher,
  otpVerificationWatcher,
  otppResendWatcher
} from "../redux/auth/auth.saga";
import {
  listManageStaffWatcher,
  staffUpdateDetailWatcher,
  sendUserCredentialsWatcher,
  deleteUserWatcher,
  newUserManageStaffWatcher,
  resetPasswordManageStaffWatcher,
  checkUserEmailWatcher,
  staffsetpermissionWatcher,
  staffgetpermissionWatcher,
  staffpermissionmoduleWatcher,
  manageStaffGetPermissionWatcher,
  staffRestoreRequestWatcher,
  staffUpdateRfpAlertWatcher,
  editUserManageStaffWatcher
} from "../redux/managestaff/manage.saga";
import {
  changedNotificationStatusWatcher,
  changePasswordSettingsWatcher,
  createNotificationAlertWatcher,
  destinationListWatcher,
  notificationListWatcher,
  originsListtWatcher,
  savePreferenceWatcher,
  deleteNotificationWatcher,
  editNotificationWatcher,
  originDestinationDataWatcher,
  syncNotificationDataWatcher,
  addSignatureSettingsDataWatcher,
  getSignatureSettingsDataWatcher,
  changeProfilePicSettingsDataWatcher,
  settingEquipmentTypeWatcher,
  settingDbLimitWatcher,
  editSignatureSettingsDataWatcher,
  deleteSignatureSettingsDataWatcher,
  listSignatureSettingsDataWatcher,
  allListSignatureSettingsDataWatcher,
  checkSignatureNameSettingsDataWatcher,
  settingRfpAlertStatusDataWatcher,
  settingRfpAlertUpdateApiDataWatcher
} from "../redux/settings/settings.saga";
import {
  copyShortCodeWatcher,
  addNewTemplateWatcher,
  templateListWatcher,
  editEmailTemplateWatcher,
  deleteEmailTemplateWatcher,
  sendEMailWatcher,
  checkTemplateNameWatcher,
  myEmailsListWatcher,
  emailStatsWatcher,
  sendSelectedFileWatcher,
  mySupportEmailsListWatcher,
  myDownloadEmailsListWatcher,
  myUnsubscribeEmailsListWatcher,
  deleteUnsubscribeEmailsWatcher
} from "../redux/manageEmails/manageEmails.saga";
import {
  addNewFaqWatcher,
  newFaqListWatcher,
  deleteFaqWatcher,
  logoutTabCloseWatcher,
} from "../redux/help/help.saga";
import {
  dashboardCarrierGraphWatcher,
  carrierRateGraphWatcher,
  equipmentTypeGraphWatcher,
  carrierCountGraphWatcher,
} from "../redux/dashboardGraphs/graphs.saga";
import {
  carrierDetailWatcher,
  carrierSourcingDownloadExcelWatcher,
  carrierSourcingListWatcher,
  carrierUpdateDetailsWatcher,
  deleteFiltersWatcher,
  destinationSearchListWatcher,
  downloadCarrierSourcingExcelDataWatcher,
  getColumnOrderFiltersWatcher,
  getFiltersWatcher,
  originDestinationDetailWatcher,
  originSearchListWatcher,
  saveCarrierRatingsWatcher,
  saveColumnOrderFiltersWatcher,
  saveFiltersWatcher,
  searchCarrierListWatcher,
  templateListDataWatcher,
  updateFiltersWatcher,
  saveNotesWatcher,
  updateBasicDetailWatcher,
  updateOperationsWatcher,
  updateCarrierContactWatcher,
  updateDispatcherContactWatcher,
  updateAuthorityDetailWatcher,
  updateFocusAreaWatcher,
  deleteMyCarrierWatcher,
  getMyCarrierWatcher,
  carrierUpdateDetailWatcher,
  carrierAddDetailWatcher,
  getMyCarrierByIdWatcher,
  getMarketDataGraphWatcher,
  getAdditionalMarketDataWatcher,
  getHeadquaterListDataWatcher,
  selectAllEmailDownloadCarrierSourcingExcelDataWatcher,
  advanceSearchEquipmentListWatcher,
  advanceMarketEquipmentListWatcher,
  dashboardDataSorceListWatcher,
  dashboardUserListWatcher,
  carrierUserListWatcher,
  getLanesAndMapWatcher,
  noteDeleteDashboardListWatcher,
  getHeadquaterSearchValueListDataWatcher,
} from "../redux/dashboard/dashboard.saga";
import {
  customNotificationListWatcher,
  // latestNotificationListWatcher,
} from "../redux/notifications/notification.saga";
import {
  dashboardfiltertrackingWatcher,
  downloadMetricsDataWatcher,
  // trackloginuserWatcher,
  userListWatcher,
  metricsTypeGraphWatcher,
  metricesUserListWatcher,
  downloadSearchFilterDataExcelWatcher
} from "../redux/metrics/metrics.saga";
import {
  reloadcarrierWatcher,
  reloadDataSourceWatcher,
  reloadEquipmentTypeListWatcher,
  reloadreportdownloadWatcher,
  selectAllEmailReloadreportdownloadWatcher,
} from "../redux/reloadcarrier/reloadcarrier.saga";
import {
  csvimportinsertcarrierWatcher,
  insertcarrierdataWatcher,
  csvimportinvalidcarrierWatcher,
  csvimportequipmenttypeWatcher,
  mcdotlistgetWatcher,
  importinvalidcarrierorigindestinationWatcher,
  insertReplacementEmaildataWatcher,
  importvalidlanesWatcher,
  insertvalidlanesdataWatcher,
  originSearchListcarrierlanesWatcher,
  destinationSearchListcarrierlanesWatcher,
  insertEquipmentTypeListWatcher,
  insertDataSourceListWatcher,
  EquipmentListInsertCarrierDataWatcher,
} from "../redux/insertcarrierdata/insertcarrierdata.saga";
import {
  carrierPortaldestinationSearchListWatcher,
  carrierPortaloriginSearchListWatcher,
  carrierPortalEquipmentTypeListWatcher,
  carrierPortalSearchResultWatcher
} from "../redux/carrierPortal/carrierPortal.saga";

import {
  equipmentTypeListWatcher,
  addLoadDestinationListWatcher,
  addLoadOriginListWatcher,
  searchByOrderIdWatcher,
  addLoadStopListWatcher,
  saveLoadDetailsWatcher,
  allLoadListWatcher,
  singleLoadDetailsWatcher,
  deleteLoadDetailsWatcher,
  updateLoadDetailsWatcher
} from "../redux/postLoad/postLoad.saga"
import {
  equipmentTypeRfpListWatcher,
  manualRfpSaveWatcher,
  submitCsvRfpRequestWatcher,
  analyzerResultTableDataWatcher,
  analyzerExportDataWatcher,
  pendingCalculationDataWatcher,
  saveRfpControlsWatcher,
  savedRfpListWatcher,
  deleteRfpWatcher,
  applyRfpWatcher,
  refreshRfpCalculationDataWatcher,
  updatedRfpListWatcher,
  rfpDownloadAlertWatcher,
  saveRangeRfpControlsWatcher
} from "../redux/rfp/rfp.saga";

import {
  usersRolesListWatcher,
  usersRolesListAllWatcher,
  usersRolesAddlWatcher,
  usersRolesEditWatcher,
  usersRolesDeletelWatcher,
  usersRoleAccessManagementListWatcher,
  usersRoleAccessManagementBatchWatcher,
  usersRoleAccessManagementVerifyWatcher
} from "../redux/manageAccess/manageAccess.saga";

export function* rootSaga() {
  yield all([
    registrationWatcher(),
    loginWatcher(),
    forgotPasswordWatcher(),
    //manageStaffAccessWatcher(),
    listManageStaffWatcher(),
    staffUpdateDetailWatcher(),
    sendUserCredentialsWatcher(),
    deleteUserWatcher(),
    verifyEmailWatcher(),
    emailVerificationWatcher(),
    logoutWatcher(),
    resetPasswordWatcher(),
    newUserManageStaffWatcher(),
    resetPasswordManageStaffWatcher(),
    changePasswordSettingsWatcher(),
    notificationListWatcher(),
    changedNotificationStatusWatcher(),
    destinationListWatcher(),
    originsListtWatcher(),
    copyShortCodeWatcher(),
    addNewTemplateWatcher(),
    templateListWatcher(),
    editEmailTemplateWatcher(),
    deleteEmailTemplateWatcher(),
    createNotificationAlertWatcher(),
    savePreferenceWatcher(),
    addNewFaqWatcher(),
    newFaqListWatcher(),
    deleteFaqWatcher(),
    deleteNotificationWatcher(),
    carrierSourcingListWatcher(),
    saveFiltersWatcher(),
    getFiltersWatcher(),
    deleteFiltersWatcher(),
    sendEMailWatcher(),
    sendSelectedFileWatcher(),
    carrierRateGraphWatcher(),
    equipmentTypeGraphWatcher(),
    carrierCountGraphWatcher(),
    saveCarrierRatingsWatcher(),
    templateListDataWatcher(),
    dashboardCarrierGraphWatcher(),
    customNotificationListWatcher(),
    // latestNotificationListWatcher(),
    checkTemplateNameWatcher(),
    checkUserEmailWatcher(),
    myEmailsListWatcher(),
    mySupportEmailsListWatcher(),
    carrierSourcingDownloadExcelWatcher(),
    emailStatsWatcher(),
    carrierUpdateDetailsWatcher(),
    editNotificationWatcher(),
    originDestinationDataWatcher(),
    syncNotificationDataWatcher(),
    getColumnOrderFiltersWatcher(),
    saveColumnOrderFiltersWatcher(),
    originSearchListWatcher(),
    destinationSearchListWatcher(),
    searchCarrierListWatcher(),
    downloadCarrierSourcingExcelDataWatcher(),
    originDestinationDetailWatcher(),
    carrierDetailWatcher(),
    updateFiltersWatcher(),
    saveNotesWatcher(),
    updateBasicDetailWatcher(),
    updateOperationsWatcher(),
    updateCarrierContactWatcher(),
    updateDispatcherContactWatcher(),
    updateAuthorityDetailWatcher(),
    updateFocusAreaWatcher(),
    staffsetpermissionWatcher(),
    staffgetpermissionWatcher(),
    staffpermissionmoduleWatcher(),
    // trackloginuserWatcher(),
    dashboardfiltertrackingWatcher(),
    userListWatcher(),
    carrierAddDetailWatcher(),
    deleteMyCarrierWatcher(),
    getMyCarrierWatcher(),
    carrierUpdateDetailWatcher(),
    getMyCarrierByIdWatcher(),
    addSignatureSettingsDataWatcher(),
    listSignatureSettingsDataWatcher(),
    editSignatureSettingsDataWatcher(),
    deleteSignatureSettingsDataWatcher(),
    allListSignatureSettingsDataWatcher(),
    checkSignatureNameSettingsDataWatcher(),
    reloadcarrierWatcher(),
    downloadMetricsDataWatcher(),
    metricsTypeGraphWatcher(),
    getSignatureSettingsDataWatcher(),
    insertcarrierdataWatcher(),
    reloadreportdownloadWatcher(),
    csvimportinsertcarrierWatcher(),
    getMarketDataGraphWatcher(),
    getAdditionalMarketDataWatcher(),
    changeProfilePicSettingsDataWatcher(),
    csvimportinvalidcarrierWatcher(),
    csvimportequipmenttypeWatcher(),
    mcdotlistgetWatcher(),
    importinvalidcarrierorigindestinationWatcher(),
    importvalidlanesWatcher(),
    insertvalidlanesdataWatcher(),
    originSearchListcarrierlanesWatcher(),
    destinationSearchListcarrierlanesWatcher(),
    carrierPortaloriginSearchListWatcher(),
    carrierPortaldestinationSearchListWatcher(),
    carrierPortalEquipmentTypeListWatcher(),
    carrierPortalSearchResultWatcher(),
    insertReplacementEmaildataWatcher(),
    linkexpireWatcher(),
    equipmentTypeListWatcher(),
    addLoadDestinationListWatcher(),
    addLoadOriginListWatcher(),
    searchByOrderIdWatcher(),
    addLoadStopListWatcher(),
    saveLoadDetailsWatcher(),
    allLoadListWatcher(),
    singleLoadDetailsWatcher(),
    deleteLoadDetailsWatcher(),
    getHeadquaterListDataWatcher(),
    metricesUserListWatcher(),
    manageStaffGetPermissionWatcher(),
    updateLoadDetailsWatcher(),
    selectAllEmailDownloadCarrierSourcingExcelDataWatcher(),
    selectAllEmailReloadreportdownloadWatcher(),
    advanceSearchEquipmentListWatcher(),
    advanceMarketEquipmentListWatcher(),
    reloadEquipmentTypeListWatcher(),
    insertEquipmentTypeListWatcher(),
    dashboardDataSorceListWatcher(),
    settingEquipmentTypeWatcher(),
    dashboardUserListWatcher(),
    carrierUserListWatcher(),
    getLanesAndMapWatcher(),
    noteDeleteDashboardListWatcher(),
    reloadDataSourceWatcher(),
    insertDataSourceListWatcher(),
    getHeadquaterSearchValueListDataWatcher(),
    settingDbLimitWatcher(),
    logoutTabCloseWatcher(),
    submitCsvRfpRequestWatcher(),
    equipmentTypeRfpListWatcher(),
    EquipmentListInsertCarrierDataWatcher(),
    manualRfpSaveWatcher(),
    analyzerResultTableDataWatcher(),
    analyzerExportDataWatcher(),
    pendingCalculationDataWatcher(),
    saveRfpControlsWatcher(),
    savedRfpListWatcher(),
    deleteRfpWatcher(),
    applyRfpWatcher(),
    refreshRfpCalculationDataWatcher(),
    downloadSearchFilterDataExcelWatcher(),
    //staffRestoreRequestWatcher(),
    myDownloadEmailsListWatcher(),
    usersRolesListWatcher(),
    usersRolesListAllWatcher(),
    usersRolesAddlWatcher(),
    usersRolesEditWatcher(),
    usersRolesDeletelWatcher(),
    usersRoleAccessManagementListWatcher(),
    usersRoleAccessManagementBatchWatcher(),
    usersRoleAccessManagementVerifyWatcher(),
    staffUpdateRfpAlertWatcher(),
    settingRfpAlertStatusDataWatcher(),
    settingRfpAlertUpdateApiDataWatcher(),
    rfpDownloadAlertWatcher(),
    staffRestoreRequestWatcher(),
    editUserManageStaffWatcher(),
    saveRangeRfpControlsWatcher(),
    updatedRfpListWatcher(),
    //myDownloadEmailsListWatcher(),
    verifyUserWatcher(),
    otpVerificationWatcher(),
    otppResendWatcher(),
    myUnsubscribeEmailsListWatcher(),
    deleteUnsubscribeEmailsWatcher()
  ]);
}
