import { message } from "antd";
import { put, takeLatest } from "redux-saga/effects";

import {
  changedNotificationStatusResponse,
  CHANGED_NOTIFICATION_STATUS,
  changePasswordSettingsResponse,
  CHANGE_PASSWORD_SETTINGS,
  createNotificationAlertResponse,
  CREATE_NOTIFICATION_ALERT,
  destinationListResponse,
  DESTINATION_LIST,
  ERROR_CHANGED_NOTIFICATION_STATUS,
  ERROR_CHANGE_PASSWORD_SETTINGS,
  ERROR_CREATE_NOTIFICATION_ALERT,
  ERROR_DESTINATION_LIST,
  ERROR_NOTIFICATION_LIST,
  ERROR_ORIGINS_LIST,
  ERROR_SAVE_PREFERENCE,
  notificationListResponse,
  NOTIFICATION_LIST,
  originsListResponse,
  ORIGINS_LIST,
  SavePreferenceResponse,
  SAVE_PREFERENCE,
  SUCCESS_CHANGED_NOTIFICATION_STATUS,
  SUCCESS_CHANGE_PASSWORD_SETTINGS,
  SUCCESS_CREATE_NOTIFICATION_ALERT,
  SUCCESS_DESTINATION_LIST,
  SUCCESS_NOTIFICATION_LIST,
  SUCCESS_ORIGINS_LIST,
  SUCCESS_SAVE_PREFERENCE,
  DELETE_NOTIFICATION,
  SUCCESS_DELETE_NOTIFICATION,
  ERROR_DELETE_NOTIFICATION,
  deleteNotificationResponse,
  editNotificationResponse,
  SUCCESS_EDIT_NOTIFICATION,
  ERROR_EDIT_NOTIFICATION,
  EDIT_NOTIFICATION,
  SUCCESS_ORIGIN_DESTINATION_DATA,
  ERROR_ORIGIN_DESTINATION_DATA,
  ORIGIN_DESTINATION_DATA,
  originDestinationDataResponse,
  SUCCESS_SYNC_NOTIFICATION_DATA,
  ERROR_SYNC_NOTIFICATION_DATA,
  syncNotificationDataResponse,
  SYNC_NOTIFICATION_DATA,
  SUCCESS_INSERT_SIGNATURE,
  ERROR_INSERT_SIGNATURE,
  INSERT_SIGNATURE,
  addSignatureResponse,
  SUCCESS_GET_SIGNATURE,
  ERROR_GET_SIGNATURE,
  getSignatureResponse,
  GET_SIGNATURE,
  changeProfilePicResponse,
  SUCCESS_Change_Profile_Pic,
  ERROR_Change_Profile_Pic,
  Change_Profile_Pic,
  settingEquipmentTypeResponse,
  SUCCESS_SETTING_EQUIPMENT_TYPE_LIST,
  ERROR_SETTING_EQUIPMENT_TYPE_LIST,
  SETTING_EQUIPMENT_TYPE_LIST,
  settingDbLimitResponse,
  SUCCESS_SETTING_DB_LIMIT,
  ERROR_SETTING_DB_LIMIT,
  SETTING_DB_LIMIT,
  EDIT_SIGNATURE,
  editSignatureResponse,
  SUCCESS_EDIT_SIGNATURE,
  ERROR_EDIT_SIGNATURE,
  DELETE_SIGNATURE,
  deleteSignatureResponse,
  SUCCESS_DELETE_SIGNATURE,
  ERROR_DELETE_SIGNATURE,
  LIST_SIGNATURE,
  listSignatureResponse,
  SUCCESS_LIST_SIGNATURE,
  ERROR_LIST_SIGNATURE,
  ALL_LIST_SIGNATURE,
  allListSignatureResponse,
  SUCCESS_ALL_LIST_SIGNATURE,
  ERROR_ALL_LIST_SIGNATURE,
  checkSignatureNameResponse,
  SUCCESS_CHECK_SIGNATURE_NAME,
  ERROR_CHECK_SIGNATURE_NAME,
  CHECK_SIGNATURE_NAME,
  SETTING_RFP_ALERT_STATUS,
  SUCCESS_SETTING_RFP_ALERT_STATUS,
  ERROR_SETTING_RFP_ALERT_STATUS,
  settingRfpAlertStatusResponse,
  SETTING_RFP_ALERT_UPDATE,
  SUCCESS_SETTING_RFP_ALERT_UPDATE,
  ERROR_SETTING_RFP_ALERT_UPDATE,
  settingRfpAlertUpdateResponse,
} from "./settings.actions";

import _ from "lodash";
import {
  changePassworSettingsdApi,
  createNotificationAlertApi,
  DestinationListApi,
  NotificationListApi,
  NotificationStatusApi,
  OriginsListApi,
  savePreferenceApi,
  deleteNotificationApi,
  editNotificationApi,
  originDestinationDataApi,
  syncNotificationDataApi,
  addSignatureApi,
  getSignatureApi,
  editSignatureApi,
  deleteSignatureApi,
  listSignatureApi,
  allListSignatureApi,
  checkSignatureNameApi,
  changeProfilePicApi,
  settingEquipmentTypeApi,
  settingDbLimitApi,
  settingRfpAlertStatusApi,
  settingRfpAlertUpdateApi
} from "../../../api/sdk/settings";

function* changePasswordSettings(userData) {
  let changePasswordSettingsData = yield changePassworSettingsdApi(userData);
  if (
    changePasswordSettingsData.success &&
    _.has(changePasswordSettingsData, "data.data")
  ) {
    yield put(
      changePasswordSettingsResponse(
        SUCCESS_CHANGE_PASSWORD_SETTINGS,
        changePasswordSettingsData.data
      )
    );
    message.success(changePasswordSettingsData.data.message);
  } else {
    yield put(
      changePasswordSettingsResponse(
        ERROR_CHANGE_PASSWORD_SETTINGS,
        changePasswordSettingsData.data
      )
    );
    //message.error(changePasswordSettingsData.data.message)
  }
}
export function* changePasswordSettingsWatcher() {
  yield takeLatest(CHANGE_PASSWORD_SETTINGS, changePasswordSettings);
}

function* notificationList(userData) {
  let notificationListData = yield NotificationListApi(userData);
  if (
    notificationListData.success &&
    _.has(notificationListData, "data.data")
  ) {
    yield put(
      notificationListResponse(
        SUCCESS_NOTIFICATION_LIST,
        notificationListData.data
      )
    );
    //message.success(notificationListData.data.message)
  } else {
    yield put(
      notificationListResponse(
        ERROR_NOTIFICATION_LIST,
        notificationListData.data
      )
    );
    //message.error(notificationListData.data.message)
  }
}
export function* notificationListWatcher() {
  yield takeLatest(NOTIFICATION_LIST, notificationList);
}

//CHANGED Notification Status
function* changedNotificationStatus(userData) {
  let changedNotificationStatusData = yield NotificationStatusApi(userData);
  if (
    changedNotificationStatusData.success &&
    _.has(changedNotificationStatusData, "data.data")
  ) {
    yield put(
      changedNotificationStatusResponse(
        SUCCESS_CHANGED_NOTIFICATION_STATUS,
        changedNotificationStatusData.data
      )
    );
    message.success(changedNotificationStatusData.data.message);
  } else {
    yield put(
      changedNotificationStatusResponse(
        ERROR_CHANGED_NOTIFICATION_STATUS,
        changedNotificationStatusData.data
      )
    );
    //message.error(changedNotificationStatusData.data.message)
  }
}
export function* changedNotificationStatusWatcher() {
  yield takeLatest(CHANGED_NOTIFICATION_STATUS, changedNotificationStatus);
}
//destination list
function* destinationList(userData) {
  let destinationsListData = yield DestinationListApi(userData);
  if (
    destinationsListData.success &&
    _.has(destinationsListData, "data.data")
  ) {
    yield put(
      destinationListResponse(
        SUCCESS_DESTINATION_LIST,
        destinationsListData.data
      )
    );
    //message.success(destinationsListData.data.message)
  } else {
    yield put(
      destinationListResponse(ERROR_DESTINATION_LIST, destinationsListData.data)
    );
    //message.error(destinationsListData.data.message)
  }
}
export function* destinationListWatcher() {
  yield takeLatest(DESTINATION_LIST, destinationList);
}
//origins list
function* originsList(userData) {
  let originsListData = yield OriginsListApi(userData);
  if (originsListData.success && _.has(originsListData, "data.data")) {
    yield put(originsListResponse(SUCCESS_ORIGINS_LIST, originsListData.data));
    //message.success(originsListData.data.message)
  } else {
    yield put(originsListResponse(ERROR_ORIGINS_LIST, originsListData.data));
    //message.error(originsListData.data.message)
  }
}
export function* originsListtWatcher() {
  yield takeLatest(ORIGINS_LIST, originsList);
}

//createNotificationAlert
function* createNotificationAlert(userData) {
  let createNotificationAlertData = yield createNotificationAlertApi(userData);
  if (
    createNotificationAlertData.success &&
    _.has(createNotificationAlertData, "data.data")
  ) {
    yield put(
      createNotificationAlertResponse(
        SUCCESS_CREATE_NOTIFICATION_ALERT,
        createNotificationAlertData.data
      )
    );
    message.success(createNotificationAlertData.data.message);
  } else {
    yield put(
      createNotificationAlertResponse(
        ERROR_CREATE_NOTIFICATION_ALERT,
        createNotificationAlertData.data
      )
    );
    //message.error(createNotificationAlertData.data.message)
  }
}
export function* createNotificationAlertWatcher() {
  yield takeLatest(CREATE_NOTIFICATION_ALERT, createNotificationAlert);
}

//save preference
function* savePreference(userData) {
  let savePreferenceData = yield savePreferenceApi(userData);
  if (savePreferenceData.success && _.has(savePreferenceData, "data.data")) {
    yield put(
      SavePreferenceResponse(SUCCESS_SAVE_PREFERENCE, savePreferenceData.data)
    );
    message.success(savePreferenceData.data.message);
  } else {
    yield put(
      SavePreferenceResponse(ERROR_SAVE_PREFERENCE, savePreferenceData.data)
    );
    //message.error(savePreferenceData.data.message)
  }
}
export function* savePreferenceWatcher() {
  yield takeLatest(SAVE_PREFERENCE, savePreference);
}

function* deleteNotification(data) {
  let deleteNotificationData = yield deleteNotificationApi(data);
  if (
    deleteNotificationData.success &&
    _.has(deleteNotificationData, "data.data")
  ) {
    yield put(
      deleteNotificationResponse(
        SUCCESS_DELETE_NOTIFICATION,
        deleteNotificationData.data
      )
    );
    message.success(deleteNotificationData.data.message);
  } else {
    yield put(
      deleteNotificationResponse(
        ERROR_DELETE_NOTIFICATION,
        deleteNotificationData.data
      )
    );
    //message.error(deleteNotificationData.data.message)
  }
}
export function* deleteNotificationWatcher() {
  yield takeLatest(DELETE_NOTIFICATION, deleteNotification);
}
//edit Notification
function* editNotification(data) {
  let editNotificationData = yield editNotificationApi(data);
  if (
    editNotificationData.success &&
    _.has(editNotificationData, "data.data")
  ) {
    yield put(
      editNotificationResponse(
        SUCCESS_EDIT_NOTIFICATION,
        editNotificationData.data
      )
    );
    message.success(editNotificationData.data.message);
  } else {
    yield put(
      editNotificationResponse(
        ERROR_EDIT_NOTIFICATION,
        editNotificationData.data
      )
    );
    //message.error(editNotificationData.data.message)
  }
}
export function* editNotificationWatcher() {
  yield takeLatest(EDIT_NOTIFICATION, editNotification);
}

//origin Dextination Data
function* originDestinationData(data) {
  let originDestinationData = yield originDestinationDataApi(data);
  if (
    originDestinationData.success &&
    _.has(originDestinationData, "data.data")
  ) {
    yield put(
      originDestinationDataResponse(
        SUCCESS_ORIGIN_DESTINATION_DATA,
        originDestinationData.data
      )
    );
    //message.success(originDestinationData.data.message)
  } else {
    yield put(
      originDestinationDataResponse(
        ERROR_ORIGIN_DESTINATION_DATA,
        originDestinationData.data
      )
    );
    //message.error(originDestinationData.data.message)
  }
}
export function* originDestinationDataWatcher() {
  yield takeLatest(ORIGIN_DESTINATION_DATA, originDestinationData);
}

//Sync Notification Data
function* syncNotificationData(data) {
  let syncNotificationData = yield syncNotificationDataApi(data);
  if (
    syncNotificationData.success &&
    _.has(syncNotificationData, "data.data")
  ) {
    yield put(
      syncNotificationDataResponse(
        SUCCESS_SYNC_NOTIFICATION_DATA,
        syncNotificationData.data
      )
    );
    //message.success(syncNotificationData.data.message)
  } else {
    yield put(
      syncNotificationDataResponse(
        ERROR_SYNC_NOTIFICATION_DATA,
        syncNotificationData.data
      )
    );
    //message.error(syncNotificationData.data.message)
  }
}
export function* syncNotificationDataWatcher() {
  yield takeLatest(SYNC_NOTIFICATION_DATA, syncNotificationData);
}

//Add Signature Data
function* addSignatureSettingsData(data) {
  let addSignatureData = yield addSignatureApi(data);
  if (addSignatureData.success && _.has(addSignatureData, "data.data")) {
    yield put(
      addSignatureResponse(SUCCESS_INSERT_SIGNATURE, addSignatureData.data)
    );
  } else {
    yield put(
      addSignatureResponse(ERROR_INSERT_SIGNATURE, addSignatureData.data)
    );
  }
}
export function* addSignatureSettingsDataWatcher() {
  yield takeLatest(INSERT_SIGNATURE, addSignatureSettingsData);
}

//Get Signature Data
function* getSignatureSettingsData(data) {
  let getSignatureData = yield getSignatureApi(data);
  if (getSignatureData.success && _.has(getSignatureData, "data.data")) {
    yield put(
      getSignatureResponse(SUCCESS_GET_SIGNATURE, getSignatureData.data)
    );
  } else {
    yield put(getSignatureResponse(ERROR_GET_SIGNATURE, getSignatureData.data));
  }
}
export function* getSignatureSettingsDataWatcher() {
  yield takeLatest(GET_SIGNATURE, getSignatureSettingsData);
}

//Change_Profile_Pic
function* changeProfilePicSettingsData(data) {
  let changeProfilePicData = yield changeProfilePicApi(data);
  if (
    changeProfilePicData.success &&
    _.has(changeProfilePicData, "data.data")
  ) {
    yield put(
      changeProfilePicResponse(
        SUCCESS_Change_Profile_Pic,
        changeProfilePicData.data
      )
    );
  } else {
    yield put(
      changeProfilePicResponse(
        ERROR_Change_Profile_Pic,
        changeProfilePicData.data
      )
    );
  }
}
export function* changeProfilePicSettingsDataWatcher() {
  yield takeLatest(Change_Profile_Pic, changeProfilePicSettingsData);
}

// equipment type 
function* settingEquipmentType(data) {
  let settingEquipmentTypedData = yield settingEquipmentTypeApi(data)
  if (settingEquipmentTypedData.success && _.has(settingEquipmentTypedData, "data.data")) {
    yield put(
      settingEquipmentTypeResponse(
        SUCCESS_SETTING_EQUIPMENT_TYPE_LIST,
        settingEquipmentTypedData.data
      )
    )
  } else {
    yield put(settingEquipmentTypeResponse(
      ERROR_SETTING_EQUIPMENT_TYPE_LIST,
      settingEquipmentTypedData.data
    ))
  }
}
export function* settingEquipmentTypeWatcher() {
  yield takeLatest(
    SETTING_EQUIPMENT_TYPE_LIST,
    settingEquipmentType
  )
}
// db limit
function* settingDbLimit(data) {
  let settingDbLimitData = yield settingDbLimitApi(data)
  if (settingDbLimitData.success && _.has(settingDbLimitData, "data.data")) {
    yield put(settingDbLimitResponse(
      SUCCESS_SETTING_DB_LIMIT,
      settingDbLimitData
    ))
  } else {
    yield put(settingDbLimitResponse(
      ERROR_SETTING_DB_LIMIT,
      settingDbLimitData
    ))
  }
}
export function* settingDbLimitWatcher() {
  yield takeLatest(SETTING_DB_LIMIT, settingDbLimit)
}

//Edit Signature Data
function* editSignatureSettingsData(data) {
  let signatureData = yield editSignatureApi(data);
  if (signatureData.success && _.has(signatureData, "data.data")) {
    yield put(
      editSignatureResponse(SUCCESS_EDIT_SIGNATURE, signatureData.data)
    );
  } else {
    yield put(
      editSignatureResponse(ERROR_EDIT_SIGNATURE, signatureData.data)
    );
  }
}
export function* editSignatureSettingsDataWatcher() {
  yield takeLatest(EDIT_SIGNATURE, editSignatureSettingsData);
}

//Delete Signature Data
function* deleteSignatureSettingsData(data) {
  let signatureData = yield deleteSignatureApi(data);
  if (signatureData.success && _.has(signatureData, "data.data")) {
    yield put(
      deleteSignatureResponse(SUCCESS_DELETE_SIGNATURE, signatureData.data)
    );
  } else {
    yield put(
      deleteSignatureResponse(ERROR_DELETE_SIGNATURE, signatureData.data)
    );
  }
}
export function* deleteSignatureSettingsDataWatcher() {
  yield takeLatest(DELETE_SIGNATURE, deleteSignatureSettingsData);
}


//list Signature Data
function* listSignatureSettingsData(data) {
  let signatureData = yield listSignatureApi(data);
  if (signatureData.success && _.has(signatureData, "data.data")) {
    yield put(
      listSignatureResponse(SUCCESS_LIST_SIGNATURE, signatureData.data)
    );
  } else {
    yield put(
      listSignatureResponse(ERROR_LIST_SIGNATURE, signatureData.data)
    );
  }
}
export function* listSignatureSettingsDataWatcher() {
  yield takeLatest(LIST_SIGNATURE, listSignatureSettingsData);
}

//list all Signature Data
function* allListSignatureSettingsData(data) {
  let signatureData = yield allListSignatureApi(data);
  if (signatureData.success && _.has(signatureData, "data.data")) {
    yield put(
      allListSignatureResponse(SUCCESS_ALL_LIST_SIGNATURE, signatureData.data)
    );
  } else {
    yield put(
      allListSignatureResponse(ERROR_ALL_LIST_SIGNATURE, signatureData.data)
    );
  }
}
export function* allListSignatureSettingsDataWatcher() {
  yield takeLatest(ALL_LIST_SIGNATURE, allListSignatureSettingsData);
}


//Check  Signature name
function* checkSignatureNameSettingsData(data) {
  let signatureData = yield checkSignatureNameApi(data);
  if (signatureData.success && _.has(signatureData, "data.data")) {
    yield put(
      checkSignatureNameResponse(SUCCESS_CHECK_SIGNATURE_NAME, signatureData.data)
    );
  } else {
    yield put(
      checkSignatureNameResponse(ERROR_CHECK_SIGNATURE_NAME, signatureData.data)
    );
  }
}
export function* checkSignatureNameSettingsDataWatcher() {
  yield takeLatest(CHECK_SIGNATURE_NAME, checkSignatureNameSettingsData);
}

//Check  settingRfpAlertUpdateApi
function* settingRfpAlertUpdateApiData(data) {
  let rfp = yield settingRfpAlertUpdateApi(data);
  if (rfp.success && _.has(rfp, "data.data")) {
    yield put(
      settingRfpAlertUpdateResponse(SUCCESS_SETTING_RFP_ALERT_UPDATE, rfp.data)
    );
  } else {
    yield put(
      settingRfpAlertUpdateResponse(ERROR_SETTING_RFP_ALERT_UPDATE, rfp.data)
    );
  }
}
export function* settingRfpAlertUpdateApiDataWatcher() {
  yield takeLatest(SETTING_RFP_ALERT_UPDATE, settingRfpAlertUpdateApiData);
}

//Check  settingRfpAlertStatus
function* settingRfpAlertStatusData(data) {
  let rfp = yield settingRfpAlertStatusApi(data);
  if (rfp.success && _.has(rfp, "data.data")) {
    yield put(
      checkSignatureNameResponse(SUCCESS_SETTING_RFP_ALERT_STATUS, rfp.data)
    );
  } else {
    yield put(
      checkSignatureNameResponse(ERROR_SETTING_RFP_ALERT_STATUS, rfp.data)
    );
  }
}
export function* settingRfpAlertStatusDataWatcher() {
  yield takeLatest(SETTING_RFP_ALERT_STATUS, settingRfpAlertStatusData);
}
