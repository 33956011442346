import {
  ERROR_RELOAD_CARRIER,
  ERROR_RELOAD_DATASOURCE_LIST,
  ERROR_RELOAD_EQUIPMENT_TYPE_LIST,
  ERROR_RELOAD_REPORT_DOWNLOAD,
  ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_RELOAD_CARRIER,
  SUCCESS_RELOAD_DATASOURCE_LIST,
  SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST,
  SUCCESS_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
} from "./reloadcarrier.action";
import { DEFAULT_STATE } from "./reloadcarrier.state";
export const reloadcarrierReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_RELOAD_CARRIER:
      const reloadcarrierData = action.data;
      return { ...state, reloadcarrierData };
    case ERROR_RELOAD_CARRIER:
      const errorreloadcarrierData = action.data;
      return { ...state, reloadcarrierData: errorreloadcarrierData };

    case SUCCESS_RELOAD_REPORT_DOWNLOAD:
      const reloadreportdownloadData = action.data;
      return { ...state, reloadreportdownloadData };
    case ERROR_RELOAD_REPORT_DOWNLOAD:
      const errorreloadreportdownloadData = action.data;
      return {
        ...state,
        reloadreportdownloadData: errorreloadreportdownloadData,
      };

    case SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD:
      const selectAllEmailReloadreportdownloadData = action.data;
      return { ...state, selectAllEmailReloadreportdownloadData };
    case ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD:
      const errorselectAllEmailReloadreportdownloadData = action.data;
      return {
        ...state,
        selectAllEmailReloadreportdownloadData: errorselectAllEmailReloadreportdownloadData,
      };

    case SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST:
      let reloadEquipmentData = action.data
      return { ...state, reloadEquipmentData }
    case ERROR_RELOAD_EQUIPMENT_TYPE_LIST:
      let errorReloadEquipmentData = action.data
      return { ...state, reloadEquipmentData: errorReloadEquipmentData }

    case SUCCESS_RELOAD_DATASOURCE_LIST:
      let reloadDataSourceData = action.data
      return { ...state, reloadDataSourceData }
    case ERROR_RELOAD_DATASOURCE_LIST:
      let errorReloadDataSourceData = action.data
      return { ...state, reloadDataSourceData: errorReloadDataSourceData }

    default:
      return state;
  }
};
