import { request } from "../request/axios.request";
import { RELOAD_CARRIER, RELOAD_DATASOURCE, RELOAD_EQUIPMENT_LIST, RELOAD_REPORT_DOWNLOAD } from "../routing/route";

export async function reloadcarrierApi(data) {
  let reloadcarrierdata = data.data;
  return request({
    url: RELOAD_CARRIER,
    method: "get",
    params: reloadcarrierdata,
  });
}

export async function reloadreportdownloadApi(data) {
  let reloadreportdownloaddata = data.data;
  return request({
    url: RELOAD_REPORT_DOWNLOAD,
    method: "get",
    params: reloadreportdownloaddata,
  });
}

export async function reloadEquipmentTypeListApi(data) {
  let reloadEquipmentTypeData = data.data
  return request({
    url: RELOAD_EQUIPMENT_LIST,
    method: "get",
    params: reloadEquipmentTypeData
  })
}
// DATA SOURCE 
export async function reloadDataSourceApi(data) {
  let reloadDataSourceData = data.data
  return request({
    url: RELOAD_DATASOURCE,
    method: "get",
    params: reloadDataSourceData
  })
}