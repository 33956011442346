export const SUBMIT_CSV_RFP = "SUBMIT_CSV_RFP"
export const submitCsvRfpList = (data) => ({ type: SUBMIT_CSV_RFP, data })
export const SUCCESS_SUBMIT_CSV_RFP = "SUCCESS_SUBMIT_CSV_RFP"
export const ERROR_SUBMIT_CSV_RFP = "ERROR_SUBMIT_CSV_RFP"
export const submitCsvRfpListResponse = (type, data) => ({ type, data })
//// equipment type
export const EQUIPMENT_TYPE_RFP = "EQUIPMENT_TYPE_RFP"
export const equipmentTypeRfpList = (data) => ({ type: EQUIPMENT_TYPE_RFP, data })
export const SUCCESS_EQUIPMENT_TYPE_RFP = "SUCCESS_EQUIPMENT_TYPE_RFP"
export const ERROR_EQUIPMENT_TYPE_RFP = "ERRPR_EQUIPMENT_TYPE_RFP"
export const equipmentTypeRfpListResponse = (type, data) => ({ type, data })

// save RFP 
export const SAVE_MANUAL_RFP_DATA = "SAVE_MANUAL_RFP_DATA"
export const saveManualRfpData = (data) => ({ type: SAVE_MANUAL_RFP_DATA, data })
export const SUCCESS_SAVE_MANUAL_RFP_DATA = "SUCCESS_SAVE_MANUAL_RFP_DATA"
export const ERROR_SAVE_MANUAL_RFP_DATA = "ERROR_SAVE_MANUAL_RFP_DATA"
export const saveManualRfpDataResponse = (type, data) => ({ type, data })

// analyzer result table data 
export const ANALYZER_RESULT_TABLE_DATA = "ANALYZER_RESULT_TABLE_DATA"
export const analyzerResultTableData = (data) => ({ type: ANALYZER_RESULT_TABLE_DATA, data })
export const SUCCESS_ANALYZER_RESULT_TABLE_DATA = "SUCCESS_ANALYZER_RESULT_TABLE_DATA"
export const ERROR_ANALYZER_RESULT_TABLE_DATA = "ERROR_ANALYZER_RESULT_TABLE_DATA"
export const analyzerResultTableDataResponse = (type, data) => ({ type, data })

// rfp refresh calculation 
export const REFRESH_RFP_CALCULATIONS = "REFRESH_RFP_CALCULATIONS"
export const refreshRfpCalculationData = (data) => ({ type: REFRESH_RFP_CALCULATIONS, data })
export const SUCCESS_REFRESH_RFP_CALCULATIONS = "SUCCESS_REFRESH_RFP_CALCULATIONS"
export const ERROR_REFRESH_RFP_CALCULATIONS = "ERROR_REFRESH_RFP_CALCULATIONS"
export const refreshRfpCalculationResponse = (type, data) => ({ type, data })
// analyzer export data 
export const ANALYZER_EXPORT_DATA = "ANALYZER_EXPORT_DATA"
export const analyzerExportData = (data) => ({ type: ANALYZER_EXPORT_DATA, data })
export const SUCCESS_ANALYZER_EXPORT_DATA = "SUCCESS_ANALYZER_EXPORT_DATA"
export const ERROR_ANALYZER_EXPORT_DATA = "ERROR_ANALYZER_EXPORT_DATA"
export const analyzerExportDataResponse = (type, data) => ({ type, data })

// pending calculation count 
export const PENDING_CALCULATION_COUNT = "PENDING_CALCULATION_COUNT"
export const pendingCalculationCount = (data) => ({ type: PENDING_CALCULATION_COUNT, data })
export const SUCCESS_PENDING_CALCULATION_COUNT = "SUCCESS_PENDING_CALCULATION_COUNT"
export const ERROR_PENDING_CALCULATION_COUNT = "ERROR_PENDING_CALCULATION_COUNT"
export const pendingCalculationCountResponse = (type, data) => ({ type, data })


// save RFP details 
export const SAVE_RFP_CONTROLS_DATA = "SAVE_RFP_CONTROLS_DATA"
export const saveRfpControlsData = (data) => ({ type: SAVE_RFP_CONTROLS_DATA, data })
export const SUCCESS_SAVE_RFP_CONTROLS_DATA = "SUCCESS_SAVE_RFP_CONTROLS_DATA"
export const ERROR_SAVE_RFP_CONTROLS_DATA = "ERROR_SAVE_RFP_CONTROLS_DATA"
export const saveRfpControlsDataResponse = (type, data) => ({ type, data })
// get all the saved RFP 
export const GET_SAVED_RFP_LIST = "GET_SAVED_RFP_LIST"
export const getSavedRfpListData = (data) => ({ type: GET_SAVED_RFP_LIST, data })
export const SUCCESS_GET_SAVED_RFP_LIST = "SUCCESS_GET_SAVED_RFP_LIST"
export const ERROR_GET_SAVED_RFP_LIST = "ERROR_GET_SAVED_RFP_LIST"
export const getSavedRfpListDataResponse = (type, data) => ({ type, data })

// delete RFP 
export const DELETE_RFP = "DELETE_RFP"
export const deleteRfp = (data) => ({ type: DELETE_RFP, data })
export const SUCCESS_DELETE_RFP = "SUCCESS_DELETE_RFP"
export const ERROR_DELETE_RFP = "ERROR_DELETE_RFP"
export const deleteRfpResponse = (type, data) => ({ type, data })

// apply rfp 
export const APPLY_SELECTED_RFP = "APPLY_SELECTED_RFP"
export const applySelectedRfp = (data) => ({ type: APPLY_SELECTED_RFP, data })
export const SUCCESS_APPLY_SELECTED_RFP = "SUCCESS_APPLY_SELECTED_RFP"
export const ERROR_APPLY_SELECTED_RFP = "ERROR_APPLY_SELECTED_RFP"
export const applySelectedRfpResponse = (type, data) => ({ type, data })

//rfpDownloadALertApi
export const RFP_DOWNLOAD_ALERT = "RFP_DOWNLOAD_ALERT"
export const rfpDownloadAlert = (data) => ({ type: RFP_DOWNLOAD_ALERT, data })
export const SUCCESS_RFP_DOWNLOAD_ALERT = "SUCCESS_RFP_DOWNLOAD_ALERT"
export const ERROR_RFP_DOWNLOAD_ALERT = "ERROR_RFP_DOWNLOAD_ALERT"
export const rfpDownloadALertResponse = (type, data) => ({ type, data })
// save Range RFP details 
export const SAVE_RANGE_RFP_CONTROLS_DATA = "SAVE_RANGE_RFP_CONTROLS_DATA"
export const saveRangeRfpControlsData = (data) => ({ type: SAVE_RANGE_RFP_CONTROLS_DATA, data })
export const SUCCESS_SAVE_RANGE_RFP_CONTROLS_DATA = "SUCCESS_SAVE_RANGE_RFP_CONTROLS_DATA"
export const ERROR_SAVE_RANGE_RFP_CONTROLS_DATA = "ERROR_SAVE_RANGE_RFP_CONTROLS_DATA"
export const saveRangeRfpControlsDataResponse = (type, data) => ({ type, data })
// update RFP details 
export const UPDATE_RFP_CONTROLS_DATA = "UPDATE_RFP_CONTROLS_DATA"
export const updateRfpControlsData = (data) => ({ type: UPDATE_RFP_CONTROLS_DATA, data })
export const SUCCESS_UPDATE_RFP_CONTROLS_DATA = "SUCCESS_UPDATE_RFP_CONTROLS_DATA"
export const ERROR_UPDATE_RFP_CONTROLS_DATA = "ERROR_UPDATE_RFP_CONTROLS_DATA"
export const updateRfpControlsDataResponse = (type, data) => ({ type, data })
