// GET RELOAD_CARRIER
export const RELOAD_CARRIER = "RELOAD_CARRIER";
export const reloadCarrierList = (data) => ({ type: RELOAD_CARRIER, data });
export const SUCCESS_RELOAD_CARRIER = "SUCCESS_RELOAD_CARRIER";
export const ERROR_RELOAD_CARRIER = "ERROR_RELOAD_CARRIER";
export const reloadCarrierListResponse = (type, data) => ({ type, data });

// GET RELOAD_REPORT_DOWNLOAD
export const RELOAD_REPORT_DOWNLOAD = "RELOAD_REPORT_DOWNLOAD";
export const reloadreportdownloadList = (data) => ({
  type: RELOAD_REPORT_DOWNLOAD,
  data,
});
export const SUCCESS_RELOAD_REPORT_DOWNLOAD = "SUCCESS_RELOAD_REPORT_DOWNLOAD";
export const ERROR_RELOAD_REPORT_DOWNLOAD = "ERROR_RELOAD_REPORT_DOWNLOAD";
export const reloadreportdownloadListResponse = (type, data) => ({
  type,
  data,
});

// GET RELOAD_REPORT_DOWNLOAD
export const SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD = "SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD";
export const selectAllEmailReloadreportdownloadList = (data) => ({
  type: SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  data,
});
export const SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD = "SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD";
export const ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD = "ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD";
export const selectAllEmailReloadreportdownloadListResponse = (type, data) => ({
  type,
  data,
});

// equipment type
export const RELOAD_EQUIPMENT_TYPE_LIST = "RELOAD_EQUIPMENT_TYPE_LIST"
export const reloadEquipmentTypeList = (data) => ({ type: RELOAD_EQUIPMENT_TYPE_LIST, data })
export const SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST = "SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST"
export const ERROR_RELOAD_EQUIPMENT_TYPE_LIST = "ERROR_RELOAD_EQUIPMENT_TYPE_LIST"
export const reloadEquipmentTypeListResponse = (type, data) => ({ type, data })

// data source
export const RELOAD_DATASOURCE_LIST = "RELOAD_DATASOURCE_LIST"
export const reloadDataSourceList = (data) => ({ type: RELOAD_DATASOURCE_LIST, data })
export const SUCCESS_RELOAD_DATASOURCE_LIST = "SUCCESS_RELOAD_DATASOURCE_LIST"
export const ERROR_RELOAD_DATASOURCE_LIST = "ERROR_RELOAD_DATASOURCE_LIST"
export const reloadDataSourceListResponse = (type, data) => ({ type, data })

