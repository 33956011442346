import {
  LOGIN,
  FORGOTPASSWORD,
  MANAGESTAFFACCESS,
  Manage_Staff_List,
  Manage_Staff_Update_Details,
  Manage_Staff_Restore,
  SEND_USER_CREDENTIALS,
  RESET_PASSWORD_ACTION,
  DELETE_USER,
  VERIFYRMAIL,
  LOGOUT,
  RESET_PASSWORD,
  EMAILVERIFICATION,
  STAFF_PERMISSION_MODULE,
  LINKEXPIREATION,
  API_MANAGE_STAFF_UPDATE_RFP_ALERT,
  USERVERIFYRMAIL,
  OTPVERIFICATION,
  OTPRESEND
} from "../routing/route";

import { request } from "../request/axios.request";

export async function registrationApi(data) {
  // let userData = data.data;
  // return request({ url: REGISTRATION, method: 'post', data: userData, headers: {
  //     'Authorization': 'Bearer ' + validToken
  //   } })
}

export async function loginApi(data) {
  let userData = data.data;
  return request({
    url: LOGIN,
    method: "post",
    data: userData,
  });
}
export async function forgotPasswordApi(data) {
  let userData = data.data;
  return request({
    url: FORGOTPASSWORD,
    method: "post",
    data: userData,
  });
}

export async function resetPasswordActionApi(data) {
  let userData = data.data;
  if (userData) {
    alert("Check your email to reset the password.");
  }
  return request({
    url: RESET_PASSWORD_ACTION,
    method: "post",
    data: userData,
  });
}

export async function manageStaffAccessApi(data) {
  let userData = data.data;
  return request({
    url: MANAGESTAFFACCESS,
    method: "post",
    data: userData,
  });
}
//logout
export async function logoutApi(data) {
  let userLogout = data.data;
  return request({ url: LOGOUT, method: "post", data: userLogout });
}

export async function listManageStaffApi(data) {
  let manageTaskData = data.data;
  return request({
    url: Manage_Staff_List,
    method: "get",
    params: manageTaskData,
  });
}

export async function staffUpdateDetailsApi(data) {
  let staffUpdateDetailsData = data.data;
  return request({
    url: Manage_Staff_Update_Details,
    method: "post",
    data: staffUpdateDetailsData,
  });
}

export async function sendUserCredentialsApi(data) {
  let sendUserCredentialsData = data.data;
  return request({
    url: SEND_USER_CREDENTIALS,
    method: "post",
    data: sendUserCredentialsData,
  });
}

export async function resetPasswordApi(data) {
  let resetPasswordData = data.data;
  return request({
    url: RESET_PASSWORD,
    method: "post",
    data: resetPasswordData,
  });
}

export async function deleteUserApi(data) {
  let deleteUserData = data.data;
  return request({ url: DELETE_USER, params: deleteUserData, method: "get" });
}
export async function verifyEmailApi(data) {
  let verifyUserData = data.data;
  return request({ url: VERIFYRMAIL, params: verifyUserData, method: "get" });
}

export async function emailVerificationApi(data) {
  let emailVerificationData = data.data;
  return request({
    url: EMAILVERIFICATION,
    method: "post",
    data: emailVerificationData,
  });
}

export async function staffpermissionmoduleApi(data) {
  let staffpermissionmoduledata = data.data;
  return request({
    url: STAFF_PERMISSION_MODULE,
    method: "get",
    data: staffpermissionmoduledata,
  });
}

export async function linkexpireApi(data) {
  let linkexpiredata = data.data;
  return request({
    url: LINKEXPIREATION,
    method: "post",
    data: linkexpiredata,
  });
}
export async function staffRestoreApi(data) {
  let staff = data.data;
  return request({ url: Manage_Staff_Restore, 
                  data: staff, 
                  method: "post" 
                });
}


export async function staffUpdateRfpAlertAPI(data) {
  let staff = data.data;
  return request({ url: API_MANAGE_STAFF_UPDATE_RFP_ALERT, data: staff, method: "post" });
}

export async function verifyUserApi(data) {
  let verifyData = data.data;
  return request({ url: USERVERIFYRMAIL, params: verifyData, method: "post" });
}
export async function otpVerificationApi(data) {
  let verifyData = data.data;
  return request({ url: OTPVERIFICATION, params: verifyData, method: "post" });
}
export async function otpResendApi(data) {
  let otpResendData = data.data;
  return request({ url: OTPRESEND, params: otpResendData, method: "post" });
}
