import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  brandlogo,
  DASHBOARD,
  HELP,
  Link,
  MANAGEMAILS,
  MANAGESTAFFACCESS,
  SETTINGS,
  useHistory,
  useDispatch,
  // useSelector, usePrevious,
  User,
  //  _,
  METRICS,
  RELOADCARRIER,
  staffgetpermission,
  NEWCARRIERDATA,
  POSTLOADDASHBOARD,
  RFPUPLOAD,
  RFPREPORT,
  HelpCenterOutlinedIcon,
  SettingsOutlinedIcon,
  SyncIcon,
  FolderCopyOutlinedIcon,
  WysiwygOutlinedIcon,
  MailOutlinedIcon,
  PersonOutlineIcon,
  GridViewOutlinedIcon,
  LocalShippingOutlinedIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  UploadOutlinedIcon,
  SummarizeOutlinedIcon,
  AccountTreeOutlinedIcon,
  // CandlestickChartOutlinedIcon,
  MANAGESUPPORTEMAILS,
  MANAGEUNSUBSCRIBEEMAILS
} from "./index";



const Sidebar = (props) => {
  const userdata = User.getUserDetails();
  const dispatch = useDispatch();
  const [currentRoute, setCurrentRoute] = useState("");
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const location = useLocation();
  // useEffect(() => {
  //     const route = (history.location.pathname).split("/");
  //     const openRoute = route[1]
  //     setCurrentRoute(openRoute)

  // }, [history.location.pathname])
  useEffect(() => {
    const route = location.pathname.split("/");
    const openRoute = route[1];
    setCurrentRoute(openRoute);
    if (openRoute !== "settings") {
      for (
        let i = 0;
        i < document.getElementsByClassName("jodit-popup").length;
        i++
      ) {
        document.getElementsByClassName("jodit-popup")[i].hidden = true;
      }
    } else {
      for (
        let i = 0;
        i < document.getElementsByClassName("jodit-popup").length - 1;
        i++
      ) {
        document.getElementsByClassName("jodit-popup")[i].hidden = true;
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(staffgetpermission({ user_id: userdata.id }));

    return history.listen((location) => {
      // const path = (history.location.pathname).split("/");
      // const mainPath = path[2]
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
        // setCurrentRoute(mainPath)
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // setCurrentRoute(mainPath)

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // setCurrentRoute(mainPath)

          // Handle back event
        }
      }
    }); // eslint-disable-next-line
  }, [locationKeys, history]);

  // const staffgetpermissionData = useSelector(state => state.managestaff.staffgetpermissionData)
  // const prevstaffgetpermissionData = usePrevious({ staffgetpermissionData });
  // const [staffpermission, setstaffpermission] = useState('')
  // useEffect(() => {
  //     if (prevstaffgetpermissionData && prevstaffgetpermissionData.staffgetpermissionData !== staffgetpermissionData) {
  //         if (staffgetpermissionData && _.has(staffgetpermissionData, 'data') && staffgetpermissionData.success === true) {

  //             setstaffpermission(staffgetpermissionData.data.permissions)

  //         }

  //     }
  // }, [staffgetpermissionData, prevstaffgetpermissionData]);// eslint-disable-line react-hooks/exhaustive-deps

  const dashboardclick = () => {
    setCurrentRoute("dashboard");
  };
  const managestaffaccessclick = () => {
    setCurrentRoute("manage-staff-access");
  };

  const manageemailsclick = () => {
    setCurrentRoute("manage-emails");
  };
  const managesupportemailsclick = () => {
    setCurrentRoute("manage-support-emails");
  };
  const metricsclick = () => {
    setCurrentRoute("metrics");
  };

  const insertcarrierdataclick = () => {
    setCurrentRoute("insert-carrier-data");
  };

  const reloadcarrierreportclick = () => {
    setCurrentRoute("reload-carrier-report");
  };

  const settingsclick = () => {
    setCurrentRoute("settings");
  };

  const helpclick = () => {
    setCurrentRoute("help");
  };
  const postLoadDashboardClick = () => {
    setCurrentRoute("post-load-dashboard");
  };

  //RFP section
  const [rfpMenuCollapseButton, setRfpMenuCollapseButton] = useState(false);
  const rfpCollapseDropdown = () => {
    setRfpMenuCollapseButton(!rfpMenuCollapseButton);
  };

  const rfpUploadClick = () => {
    setCurrentRoute("RFP-upload");
  };

  // const rfpResultClick = () => {
  //   setCurrentRoute("RFP-result");
  // };

  const rfpReportClick = () => {
    setCurrentRoute("RFP-report");
  };
  const manageunsubscribeemailsclick = () => {
    setCurrentRoute("manage-unsubscribe-emails");
  };

  // Email section
  const [emailMenuCollapseButton, setEmailMenuCollapseButton] = useState(false);
  const emailCollapseDropdown = () => {
    setEmailMenuCollapseButton(!emailMenuCollapseButton);
  };
  return (
    <>
      <aside className="sidenav-main test nav-expanded nav-lock nav-collapsible  sidenav-light sidenav-active-square">
        <div className="brand-sidebar">
          <h1 className="logo-wrapper d-flex align-items-center position-relative">
            <Link to={DASHBOARD} className="brand-logo" href="#">
              <img className=" hide-on-collapse" src={brandlogo} alt="logo" />
              {/* <img className="show-on-collapse hide-on-full" src={appicon} alt="logo" /> */}
            </Link>
            {/*  eslint-disable-next-line */}
            <a className="navbar-toggler" role="button">
              <span
                id="hamburger_icon_clicked"
                className="hamburger_icon sideBartoggle hamburger_icon_menus"
              >
                Sidebar Toggle Button
                {/* <div class="menu-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div> */}
              </span>
            </a>
          </h1>
        </div>
        <ul
          className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
          id="slide-out"
          data-menu="menu-navigation"
          data-collapsible="menu-accordion"
        >
          <li
            onClick={dashboardclick}
            className={
              currentRoute === "dashboard" || currentRoute === "carrier-details"
                ? "bold active"
                : "bold"
            }
          >
            <Link to={DASHBOARD} className="waves-effect waves-cyan">
              {/* <i className="navico dash"></i> */}
              {/* <span className="material-symbols-outlined">grid_view</span> */}
              <GridViewOutlinedIcon className="me-1" />
              <span className="menu-title">Carrier Search</span>
              {/* <span className="badge badge pill orange float-right mr-10">3</span> */}
            </Link>
          </li>
          {props.userData?.user_role_id === 1 ||
            props.userData?.user_role_id === "1" || props.userData?.is_manager === 1 || props.userData?.is_manager === "1"? (
            <li
              onClick={managestaffaccessclick}
              className={
                currentRoute === "manage-staff-access" ||
                  currentRoute === "staff-permission"
                  ? "bold active"
                  : "bold"
              }
            >
              <Link to={MANAGESTAFFACCESS} className="waves-effect waves-cyan">
                {/* <i className="navico staff"></i> */}
                {/* <span className="material-symbols-outlined">person</span> */}
                <PersonOutlineIcon className="me-1" />
                <span className="menu-title">Manage Staff Access</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* <li
            onClick={manageemailsclick}
            className={
              currentRoute === "manage-emails" ? "bold active" : "bold"
            }
          >
            <Link to={MANAGEMAILS} className="waves-effect waves-cyan">
              {/* <i className="navico email"></i> */}
              {/* <span className="material-symbols-outlined">mail</span> */}
              {/* <MailOutlinedIcon className="me-1" />
              <span className="menu-title">Manage Emails</span>
            </Link>
          </li>  */}
           {/* {(props.staffpermission &&
                  props.staffpermission.length > 0 &&
                  props.staffpermission[
                    props.staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Emails"
                    )
                  ]?.module?.module_name === "Manage Emails" &&
                  props.staffpermission[
                    props.staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Emails"
                    )
                  ].is_enabled === 1) && ( 
                  <>  */}
           <li className=" rfpSectionList" onClick={emailCollapseDropdown}>
            <button className="rfpSectionButton waves-effect waves-cyan" style={currentRoute === "manage-emails" || currentRoute === "manage-unsubscribe-emails" ? {color:'#fe0000'} : null}>
            <MailOutlinedIcon className="me-1" />
              <span className="menu-title">Manage Emails</span>
              {emailMenuCollapseButton ? <ExpandLessIcon className="ms-auto" /> : <ExpandMoreIcon className="ms-auto" />}
            </button>
          </li>

          {emailMenuCollapseButton && (
          <div style={{marginLeft:'20px',listStyleType: 'none'}}>
            <li
              onClick={manageemailsclick}
              className={currentRoute === "manage-emails" ? "bold active" : "bold"}
            >
              <Link to={MANAGEMAILS} className="waves-effect waves-cyan">
              <MailOutlinedIcon className="me-1" />
                <span className="menu-title">Emails</span>
              </Link>
            </li>
            {(props.staffpermission &&
                  props.staffpermission.length > 0 &&
                  props.staffpermission[
                    props.staffpermission.findIndex(
                      (data) => data.module.module_name === "Unsubscribe Emails"
                    )
                  ]?.module?.module_name === "Unsubscribe Emails" &&
                  props.staffpermission[
                    props.staffpermission.findIndex(
                      (data) => data.module.module_name === "Unsubscribe Emails"
                    )
                  ].is_enabled === 1) && ( 
          <li
            onClick={manageunsubscribeemailsclick}
            className={
              currentRoute === "manage-unsubscribe-emails" ? "bold active" : "bold"
            }
          >
            <Link to={MANAGEUNSUBSCRIBEEMAILS} className="waves-effect waves-cyan">
              <MailOutlinedIcon className="me-1" />
              <span className="menu-title">Unsubscribe Emails</span>
            </Link>
          </li>
                  )}
                  {(props.staffpermission &&
                  props.staffpermission.length > 0 &&
                  props.staffpermission[
                    props.staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Support Emails"
                    )
                  ]?.module?.module_name === "Manage Support Emails" &&
                  props.staffpermission[
                    props.staffpermission.findIndex(
                      (data) => data.module.module_name === "Manage Support Emails"
                    )
                  ].is_enabled === 1) && ( 
          <li
            onClick={managesupportemailsclick}
            className={
              currentRoute === "manage-support-emails" ? "bold active" : "bold"
            }
          >
            <Link to={MANAGESUPPORTEMAILS} className="waves-effect waves-cyan">
              {/* <i className="navico email"></i> */}
              {/* <span className="material-symbols-outlined">mail</span> */}
              <MailOutlinedIcon className="me-1" />
              <span className="menu-title">Support Emails</span>
            </Link>
          </li>
          )}
             </div>
             
          )}
          {/* </>
          )} */}

          <li
            onClick={metricsclick}
            className={currentRoute === "metrics" ? "bold active" : "bold"}
          >
            <Link to={METRICS} className="waves-effect waves-cyan">
              {/* <i className="navico metrix"></i> */}
              {/* <span className="material-symbols-outlined">wysiwyg</span> */}
              <WysiwygOutlinedIcon className="me-1" />
              <span className="menu-title">Metrics</span>
            </Link>
          </li>
          <li
            onClick={insertcarrierdataclick}
            className={
              currentRoute === "insert-carrier-data" ? "bold active" : "bold"
            }
          >
            <Link to={NEWCARRIERDATA} className="waves-effect waves-cyan">
              {/* <i className="navico insertcarrierdata"></i> */}
              {/* <span className="mater?ial-symbols-outlined">folder_copy</span> */}
              <FolderCopyOutlinedIcon className="me-1" />
              <span className="menu-title">Insert Carrier Data</span>
            </Link>
          </li>
          <li
            onClick={reloadcarrierreportclick}
            className={
              currentRoute === "reload-carrier-report" ? "bold active" : "bold"
            }
          >
            <Link to={RELOADCARRIER} className="waves-effect waves-cyan">
              {/* <i className="navico reloadcarrier"></i> */}
              {/* <span className="material-symbols-outlined">sync</span> */}
              <SyncIcon className="me-1" />
              <span className="menu-title">Reload Carrier</span>
            </Link>
          </li>

          {/* ******************************************************************************************** */}
          {/* RFP section */}
          {(props.userData?.user_role_id === 1 || props.userData?.user_role_id === 69 || props.userData?.user_role_id === 70) ||
            (props.userData?.user_role_id === "1" || props.userData?.user_role_id === "69" || props.userData?.user_role_id === "70") ? (
          <li className="rfpSectionList" onClick={rfpCollapseDropdown}>
            <button className=" rfpSectionButton waves-effect waves-cyan" style={currentRoute === "RFP-upload" || currentRoute === "RFP-report" ? {color:'#fe0000'} : null}>
              <AccountTreeOutlinedIcon className="me-1" />
              <span className="menu-title">RFP</span>
              {rfpMenuCollapseButton ? <ExpandLessIcon className="ms-auto" /> : <ExpandMoreIcon className="ms-auto" />}
            </button>
          </li>
          ) : (
            ""
          )}
         {rfpMenuCollapseButton && (
          <div style={{marginLeft:'20px',listStyleType: 'none'}}>
            <li
              onClick={rfpUploadClick}
              className={currentRoute === "RFP-upload" ? "bold active" : "bold"}
            >
              <Link to={RFPUPLOAD} className="waves-effect waves-cyan">
                <UploadOutlinedIcon className="me-1" />
                <span className="menu-title">Upload</span>
              </Link>
            </li>
             <li
             onClick={rfpReportClick}
             className={currentRoute === "RFP-report" ? "bold active" : "bold"}
           >
             <Link to={RFPREPORT} className="waves-effect waves-cyan">
               <SummarizeOutlinedIcon className="me-1" />
               <span className="menu-title">Report</span>
             </Link>
           </li>
             </div>
          )}

          {/* {rfpMenuCollapseButton && (
            <li
              onClick={rfpResultClick}
              className={currentRoute === "RFP-result" ? "bold active" : "bold"}
            >
              <Link to={RFPRESULT} className="waves-effect waves-cyan">
                <CandlestickChartOutlinedIcon className="me-1" />
                <span className="menu-title">RFP Result</span>
              </Link>
            </li>
          )} */}

          {/* ******************************************************************************************** */}


          <li
            onClick={settingsclick}
            className={currentRoute === "settings" ? "bold active" : "bold"}
          >
            <Link className="waves-effect waves-cyan" to={SETTINGS}>
              {/* <i className="navico settings"></i> */}
              {/* <span className="material-symbols-outlined">settings</span> */}
              <SettingsOutlinedIcon className="me-1" />
              <span className="menu-title">Settings</span>
            </Link>
          </li>
          <li
            onClick={helpclick}
            className={currentRoute === "help" ? "bold active" : "bold"}
          >
            <Link to={HELP} className="waves-effect waves-cyan">
              <HelpCenterOutlinedIcon className="me-1" />
              <span className="menu-title">Help</span>
            </Link>
          </li>

          <li
            className={currentRoute === "posting-load" ? "bold active" : "bold"}
            onClick={postLoadDashboardClick}
          >
            <Link to={POSTLOADDASHBOARD} className="waves-effect waves-cyan">
              <LocalShippingOutlinedIcon className="me-1" />
              <span className="menu-title">Post load</span>
            </Link>
          </li>
          
            {/* {props.userData?.user_role_id === 1 ||
            props.userData?.user_role_id === "1" ? (
          <li
            onClick={manageunsubscribeemailsclick}
            className={
              currentRoute === "manage-unsubscribe-emails" ? "bold active" : "bold"
            }
          >
            <Link to={MANAGEUNSUBSCRIBEEMAILS} className="waves-effect waves-cyan">
              {/* <i className="navico email"></i> */}
              {/* <span className="material-symbols-outlined">mail</span> */}
              {/* <MailOutlinedIcon className="me-1" />
              <span className="menu-title">Manage Unsubscribe Emails</span>
            </Link>
          </li> */}
             {/* ) : (
              ""
            )} */}
            
        </ul>
        <div className="navigation-background"></div>
        <a
          className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-light hide-on-large-only"
          href="/#"
          data-toggle="collapse"
          data-target="slide-out"
        >
          <i className="material-icons">menu</i>
        </a>
      </aside>
    </>
  );
};

export default Sidebar;
