import { message } from "antd";
import { put, takeLatest } from "redux-saga/effects";

import {
  SUCCESS_LIST_MANAGE_STAFF,
  ERROR_LIST_MANAGE_STAFF,
  LIST_MANAGE_STAFF,
  listManageStaffResponse,
  SUCCESS_STAFF_UPDATE_DETAILS,
  ERROR_STAFF_UPDATE_DETAILS,
  STAFF_UPDATE_DETAILS,
  staffUpdateDetailsResponse,
  sendUserCredentialsResponse,
  SEND_USER_CREDENTIALS,
  SUCCESS_SEND_USER_CREDENTIALS,
  ERROR_SEND_USER_CREDENTIALS,
  SUCCESS_DELETE_USER,
  ERROR_DELETE_USER,
  DELETE_USER,
  deleteUserResponse,
  NEW_USER_MANAGESTAFF,
  newUserManageStaffResponse,
  SUCCESS_NEW_USER_MANAGESTAFF,
  ERROR_NEW_USER_MANAGESTAFF,
  RESET_PASSWORD_MANAGESTAFF,
  SUCCESS_RESET_PASSWORD_MANAGESTAFF,
  resetPasswordManageStaffResponse,
  checkUserEmailResponse,
  SUCCESS_CHECK_USER_EMAIL,
  ERROR_CHECK_USER_EMAIL,
  CHECK_USER_EMAIL,
  staffsetpermissionResponse,
  SUCCESS_STAFF_SET_PERMISSION,
  ERROR_STAFF_SET_PERMISSION,
  STAFF_SET_PERMISSION,
  staffgetpermissionResponse,
  SUCCESS_STAFF_GET_PERMISSION,
  ERROR_STAFF_GET_PERMISSION,
  STAFF_GET_PERMISSION,
  SUCCESS_STAFF_PERMISSION_MODULE,
  ERROR_STAFF_PERMISSION_MODULE,
  STAFF_PERMISSION_MODULE,
  staffpermissionmoduleResponse,
  manageStaffGetPermissionResponse,
  SUCCESS_MANAGE_STAFF_GET_PERMISSION,
  ERROR_MANAGE_STAFF_GET_PERMISSION,
  MANAGE_STAFF_GET_PERMISSION,
  STAFF_RESTORE,
  SUCCESS_STAFF_RESTORE,
  ERROR_STAFF_RESTORE,
  staffRestoreResponse,
  STAFF_UPDATE_RFP_ALERT,
  SUCCESS_STAFF_UPDATE_RFP_ALERT,
  ERROR_STAFF_UPDATE_RFP_ALERT,
  staffUpdateRfpAlertResponse,
  EDIT_USER_MANAGESTAFF,
  editUserManageStaffResponse,
  SUCCESS_EDIT_USER_MANAGESTAFF,
  ERROR_EDIT_USER_MANAGESTAFF,
} from "./manage.action";

import _ from "lodash";
import {
  listManageStaffApi,
  staffUpdateDetailsApi,
  sendUserCredentialsApi,
  deleteUserApi,
  staffpermissionmoduleApi,
  staffRestoreApi,
  staffUpdateRfpAlertAPI
} from "../../../api/sdk/auth";
import {
  newUserManageStaffApi,
  resetPassworManageStaffdApi,
  checkUserEmailApi,
  staffsetpermissionApi,
  staffgetpermissionApi,
  editUserManageStaffApi,
} from "../../../api/sdk/managestaff";

function* listManageStaffRequest(userData) {
  let listManageStaff = yield listManageStaffApi(userData);
  if (listManageStaff.success && _.has(listManageStaff, "data.data")) {
    yield put(
      listManageStaffResponse(SUCCESS_LIST_MANAGE_STAFF, listManageStaff.data)
    );
  } else {
    yield put(
      listManageStaffResponse(ERROR_LIST_MANAGE_STAFF, listManageStaff.data)
    );
  }
}
export function* listManageStaffWatcher() {
  yield takeLatest(LIST_MANAGE_STAFF, listManageStaffRequest);
}

function* staffUpdateDetailsRequest(userData) {
  let staffUpdateDetail = yield staffUpdateDetailsApi(userData);
  if (staffUpdateDetail.success && _.has(staffUpdateDetail, "data.data")) {
    yield put(
      staffUpdateDetailsResponse(
        SUCCESS_STAFF_UPDATE_DETAILS,
        staffUpdateDetail.data
      )
    );
  } else {
    yield put(
      staffUpdateDetailsResponse(
        ERROR_STAFF_UPDATE_DETAILS,
        staffUpdateDetail.data
      )
    );
  }
}
export function* staffUpdateDetailWatcher() {
  yield takeLatest(STAFF_UPDATE_DETAILS, staffUpdateDetailsRequest);
}
//
function* sendUserCredentialsRequest(userData) {
  let sendUserCredentialsData = yield sendUserCredentialsApi(userData);
  if (
    sendUserCredentialsData.success &&
    _.has(sendUserCredentialsData, "data.data")
  ) {
    yield put(
      sendUserCredentialsResponse(
        SUCCESS_SEND_USER_CREDENTIALS,
        sendUserCredentialsData.data
      )
    );
    message.success({
      content: sendUserCredentialsData.data.message,
      style: { marginRight: "0px", padding: "20px 25px" },
    });
  } else {
    yield put(
      sendUserCredentialsResponse(
        ERROR_SEND_USER_CREDENTIALS,
        sendUserCredentialsData.data
      )
    );
    //message.error(sendUserCredentialsData.data.message)
  }
}
export function* sendUserCredentialsWatcher() {
  yield takeLatest(SEND_USER_CREDENTIALS, sendUserCredentialsRequest);
}

function* deleteUserRequest(userData) {
  let deleteUserData = yield deleteUserApi(userData);
  if (deleteUserData.success && _.has(deleteUserData, "data.data")) {
    yield put(deleteUserResponse(SUCCESS_DELETE_USER, deleteUserData.data));
    message.success(deleteUserData.data.message);
  } else {
    yield put(deleteUserResponse(ERROR_DELETE_USER, deleteUserData.data));
    //errorPopUp(deleteUserData.data.message)
  }
}
export function* deleteUserWatcher() {
  yield takeLatest(DELETE_USER, deleteUserRequest);
}

//Add New user
function* newUserManageStaffRequest(userData) {
  let getData = yield newUserManageStaffApi(userData);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      newUserManageStaffResponse(SUCCESS_NEW_USER_MANAGESTAFF, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(
      newUserManageStaffResponse(ERROR_NEW_USER_MANAGESTAFF, getData.data)
    );
  }
}

export function* newUserManageStaffWatcher() {
  yield takeLatest(NEW_USER_MANAGESTAFF, newUserManageStaffRequest);
}
function* resetPasswordManageStaff(userData) {
  let resetPasswordData = yield resetPassworManageStaffdApi(userData);
  if (resetPasswordData.success && _.has(resetPasswordData, "data.data")) {
    yield put(
      resetPasswordManageStaffResponse(
        SUCCESS_RESET_PASSWORD_MANAGESTAFF,
        resetPasswordData.data
      )
    );
    message.success(resetPasswordData.data.message);
  } else {
    yield put(
      resetPasswordManageStaffResponse(
        ERROR_CHECK_USER_EMAIL,
        resetPasswordData.data
      )
    );
    //message.error(resetPasswordData.data.message)
  }
}
export function* resetPasswordManageStaffWatcher() {
  yield takeLatest(RESET_PASSWORD_MANAGESTAFF, resetPasswordManageStaff);
}

function* checkUserEmailRequest(data) {
  let UserData = yield checkUserEmailApi(data);
  if (UserData.success && _.has(UserData, "data.data")) {
    yield put(checkUserEmailResponse(SUCCESS_CHECK_USER_EMAIL, UserData.data));
    // message.success(UserData.data.message)
  } else {
    yield put(checkUserEmailResponse(ERROR_CHECK_USER_EMAIL, UserData.data));
    // message.error(UserData.data.message)
  }
}
export function* checkUserEmailWatcher() {
  yield takeLatest(CHECK_USER_EMAIL, checkUserEmailRequest);
}

// staff set permission
function* staffsetpermissionRequest(data) {
  let getData = yield staffsetpermissionApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      staffsetpermissionResponse(SUCCESS_STAFF_SET_PERMISSION, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      staffsetpermissionResponse(ERROR_STAFF_SET_PERMISSION, getData.data)
    );
    // message.error(getData.data.message)
  }
}
export function* staffsetpermissionWatcher() {
  yield takeLatest(STAFF_SET_PERMISSION, staffsetpermissionRequest);
}

function* staffgetpermissionRequest(data) {
  let getData = yield staffgetpermissionApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      staffgetpermissionResponse(SUCCESS_STAFF_GET_PERMISSION, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      staffgetpermissionResponse(ERROR_STAFF_GET_PERMISSION, getData.data)
    );
    // message.error(getData.data.message)
  }
}
export function* staffgetpermissionWatcher() {
  yield takeLatest(STAFF_GET_PERMISSION, staffgetpermissionRequest);
}

function* staffpermissionmoduleRequest(data) {
  let getData = yield staffpermissionmoduleApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      staffpermissionmoduleResponse(
        SUCCESS_STAFF_PERMISSION_MODULE,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      staffpermissionmoduleResponse(ERROR_STAFF_PERMISSION_MODULE, getData.data)
    );
    // message.error(getData.data.message)
  }
}
export function* staffpermissionmoduleWatcher() {
  yield takeLatest(STAFF_PERMISSION_MODULE, staffpermissionmoduleRequest);
}

function* manageStaffGetPermissionRequest(data) {
  let getData = yield staffgetpermissionApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      manageStaffGetPermissionResponse(
        SUCCESS_MANAGE_STAFF_GET_PERMISSION,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      manageStaffGetPermissionResponse(
        ERROR_MANAGE_STAFF_GET_PERMISSION,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* manageStaffGetPermissionWatcher() {
  yield takeLatest(
    MANAGE_STAFF_GET_PERMISSION,
    manageStaffGetPermissionRequest
  );
}  
                                          
function* staffRestoreRequest(data) {
  let getData = yield staffRestoreApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      staffRestoreResponse(
        SUCCESS_STAFF_RESTORE,
        getData.data
      )
    );
  } else {
    yield put(
      staffRestoreResponse(
        ERROR_STAFF_RESTORE,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* staffRestoreRequestWatcher() {
  yield takeLatest(
    STAFF_RESTORE,
    staffRestoreRequest
  );
}             

function* staffUpdateRfpAlertRequest(data) {
  let getData = yield staffUpdateRfpAlertAPI(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
     staffUpdateRfpAlertResponse(
        SUCCESS_STAFF_UPDATE_RFP_ALERT,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      staffUpdateRfpAlertResponse(
        ERROR_STAFF_UPDATE_RFP_ALERT,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* staffUpdateRfpAlertWatcher() {
  yield takeLatest(
    STAFF_UPDATE_RFP_ALERT,
    staffUpdateRfpAlertRequest
  );
}
           

//Edit user profile
function* editUserManageStaffRequest(userData) {
  let getData = yield editUserManageStaffApi(userData);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      editUserManageStaffResponse(SUCCESS_EDIT_USER_MANAGESTAFF, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(
      editUserManageStaffResponse(ERROR_EDIT_USER_MANAGESTAFF, getData.data)
    );
  }
}
export function* editUserManageStaffWatcher() {
  yield takeLatest(EDIT_USER_MANAGESTAFF, editUserManageStaffRequest);
}