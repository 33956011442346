import { put, takeLatest } from "redux-saga/effects"
import _ from "lodash"
import { equipmentTypeRfpApi, rfpImportCsvApi, saveManualRfpApi, analyzerResultDataApi, analyzerExportDataApi, pendingCalculationApi, saveRfpWithControlsApi, getSavedRfpListApi, deleteRfpApi, applyRfpApi, refreshRfpCalculationApi,getUpdatedRfpListApi,rfpDownloadALertApi,saveRangeRfpWithControlsApi} from "../../../api/sdk/rfp"
import {
    equipmentTypeRfpListResponse,
    EQUIPMENT_TYPE_RFP,
    ERROR_EQUIPMENT_TYPE_RFP,
    ERROR_SAVE_MANUAL_RFP_DATA,
    ERROR_SUBMIT_CSV_RFP,
    saveManualRfpDataResponse,
    SAVE_MANUAL_RFP_DATA,
    submitCsvRfpListResponse,
    SUBMIT_CSV_RFP,
    SUCCESS_EQUIPMENT_TYPE_RFP,
    SUCCESS_SAVE_MANUAL_RFP_DATA,
    SUCCESS_SUBMIT_CSV_RFP,
    analyzerResultTableDataResponse,
    SUCCESS_ANALYZER_RESULT_TABLE_DATA,
    ERROR_ANALYZER_RESULT_TABLE_DATA,
    ANALYZER_RESULT_TABLE_DATA,
    analyzerExportDataResponse,
    SUCCESS_ANALYZER_EXPORT_DATA,
    ERROR_ANALYZER_EXPORT_DATA,
    ANALYZER_EXPORT_DATA,
    pendingCalculationCountResponse,
    SUCCESS_PENDING_CALCULATION_COUNT,
    ERROR_PENDING_CALCULATION_COUNT,
    PENDING_CALCULATION_COUNT,
    saveRfpControlsDataResponse,
    SUCCESS_SAVE_RFP_CONTROLS_DATA,
    ERROR_SAVE_RFP_CONTROLS_DATA,
    SAVE_RFP_CONTROLS_DATA,
    getSavedRfpListDataResponse,
    SUCCESS_GET_SAVED_RFP_LIST,
    ERROR_GET_SAVED_RFP_LIST,
    GET_SAVED_RFP_LIST,
    deleteRfpResponse,
    SUCCESS_DELETE_RFP,
    ERROR_DELETE_RFP,
    DELETE_RFP,
    applySelectedRfpResponse,
    SUCCESS_APPLY_SELECTED_RFP,
    ERROR_APPLY_SELECTED_RFP,
    APPLY_SELECTED_RFP,
    refreshRfpCalculationResponse,
    SUCCESS_REFRESH_RFP_CALCULATIONS,
    ERROR_REFRESH_RFP_CALCULATIONS,
    REFRESH_RFP_CALCULATIONS,
    SUCCESS_RFP_DOWNLOAD_ALERT,
    ERROR_RFP_DOWNLOAD_ALERT,
    rfpDownloadALertResponse,
    RFP_DOWNLOAD_ALERT,
    saveRangeRfpControlsDataResponse,
    SUCCESS_SAVE_RANGE_RFP_CONTROLS_DATA,
    ERROR_SAVE_RANGE_RFP_CONTROLS_DATA,
    SAVE_RANGE_RFP_CONTROLS_DATA,
    updateRfpControlsDataResponse,
    SUCCESS_UPDATE_RFP_CONTROLS_DATA,
    ERROR_UPDATE_RFP_CONTROLS_DATA,
    UPDATE_RFP_CONTROLS_DATA,
} from "./rfp.action"


function* submitCsvRfpRequest(data) {
    let submitCsvRfpRequestData = yield rfpImportCsvApi(data)
    if (submitCsvRfpRequestData.success && _.has(submitCsvRfpRequestData, "data.data")) {
        yield put(submitCsvRfpListResponse(SUCCESS_SUBMIT_CSV_RFP, submitCsvRfpRequestData.data))
    } else {
        yield put(submitCsvRfpListResponse(ERROR_SUBMIT_CSV_RFP, submitCsvRfpRequestData.data))
    }
}
export function* submitCsvRfpRequestWatcher() {
    yield takeLatest(SUBMIT_CSV_RFP, submitCsvRfpRequest)
}

function* equipmentTypeRfpList(data) {
    let equipmentTypeRfpListData = yield equipmentTypeRfpApi(data)
    if (equipmentTypeRfpListData && _.has(equipmentTypeRfpListData, "data.data")) {
        yield put(equipmentTypeRfpListResponse(SUCCESS_EQUIPMENT_TYPE_RFP, equipmentTypeRfpListData.data))
    } else {
        yield put(equipmentTypeRfpListResponse(ERROR_EQUIPMENT_TYPE_RFP, equipmentTypeRfpListData.data))
    }
}
export function* equipmentTypeRfpListWatcher() {
    yield takeLatest(EQUIPMENT_TYPE_RFP, equipmentTypeRfpList)
}

function* manualRfpSave(data) {
    let manualRfpSaveData = yield saveManualRfpApi(data)
    if (manualRfpSaveData && _.has(manualRfpSaveData, "data.data")) {
        yield put(saveManualRfpDataResponse(
            SUCCESS_SAVE_MANUAL_RFP_DATA,
            manualRfpSaveData.data
        ))
    } else {
        yield put(saveManualRfpDataResponse(
            ERROR_SAVE_MANUAL_RFP_DATA,
            manualRfpSaveData.data
        ))
    }
}
export function* manualRfpSaveWatcher() {
    yield takeLatest(SAVE_MANUAL_RFP_DATA, manualRfpSave)
}




//analyzer result table data
function* analyzerResultTableData(data) {
    let analyzerResultTableDataList = yield analyzerResultDataApi(data)
    if (analyzerResultTableDataList && _.has(analyzerResultTableDataList, "data.data")) {
        yield put(analyzerResultTableDataResponse(
            SUCCESS_ANALYZER_RESULT_TABLE_DATA,
            analyzerResultTableDataList.data
        ))
    } else {
        yield put(analyzerResultTableDataResponse(
            ERROR_ANALYZER_RESULT_TABLE_DATA,
            analyzerResultTableDataList.data
        ))
    }
}

export function* analyzerResultTableDataWatcher() {
    yield takeLatest(ANALYZER_RESULT_TABLE_DATA, analyzerResultTableData)
}

function* analyzerExportData(data) {
    let analyzerExportDataList = yield analyzerExportDataApi(data)
    if (analyzerExportDataList && _.has(analyzerExportDataList, "data.data")) {
        yield put(analyzerExportDataResponse(
            SUCCESS_ANALYZER_EXPORT_DATA,
            analyzerExportDataList.data
        ))
    } else {
        yield put(analyzerExportDataResponse(
            ERROR_ANALYZER_EXPORT_DATA,
            analyzerExportDataList.data
        ))
    }
}

export function* analyzerExportDataWatcher() {
    yield takeLatest(ANALYZER_EXPORT_DATA, analyzerExportData)
}

function* pendingCalculationData(data) {
    let pendingCalculationCount = yield pendingCalculationApi(data)
    if (pendingCalculationCount && _.has(pendingCalculationCount, "data.data")) {
        yield put(pendingCalculationCountResponse(
            SUCCESS_PENDING_CALCULATION_COUNT,
            pendingCalculationCount.data
        ))
    } else {
        yield put(pendingCalculationCountResponse(
            ERROR_PENDING_CALCULATION_COUNT,
            pendingCalculationCount.data
        ))
    }
}
export function* pendingCalculationDataWatcher() {
    yield takeLatest(PENDING_CALCULATION_COUNT, pendingCalculationData)
}

function* refreshRfpCalculationData(data) {
    let refreshRfpCalculationData = yield refreshRfpCalculationApi(data)
   
    if (refreshRfpCalculationData && _.has(refreshRfpCalculationData, "data.data")) {
        yield put(refreshRfpCalculationResponse(
            SUCCESS_REFRESH_RFP_CALCULATIONS,
            refreshRfpCalculationData.data
        ))
        
    } else {
        yield put(refreshRfpCalculationResponse(
            ERROR_REFRESH_RFP_CALCULATIONS,
            refreshRfpCalculationData.data
        ))
    }
}

export function* refreshRfpCalculationDataWatcher() {
    yield takeLatest(REFRESH_RFP_CALCULATIONS, refreshRfpCalculationData)
}
function* saveRfpControls(data) {
    let saveRfpControlsData = yield saveRfpWithControlsApi(data)
    if (saveRfpControlsData && _.has(saveRfpControlsData, "data.data")) {
        yield put(saveRfpControlsDataResponse(
            SUCCESS_SAVE_RFP_CONTROLS_DATA,
            saveRfpControlsData.data
        ))
    } else {
        yield put(saveRfpControlsDataResponse(
            ERROR_SAVE_RFP_CONTROLS_DATA,
            saveRfpControlsData.data
        ))
    }
}
export function* saveRfpControlsWatcher() {
    yield takeLatest(SAVE_RFP_CONTROLS_DATA, saveRfpControls)
}

function* savedRfpList(data) {
    let savedRfpListData = yield getSavedRfpListApi(data)
    if (savedRfpListData && _.has(savedRfpListData, "data.data")) {
        yield put(getSavedRfpListDataResponse(
            SUCCESS_GET_SAVED_RFP_LIST,
            savedRfpListData.data
        ))
    } else {
        yield put(getSavedRfpListDataResponse(
            ERROR_GET_SAVED_RFP_LIST,
            savedRfpListData.data
        ))
    }
}
export function* savedRfpListWatcher() {
    yield takeLatest(GET_SAVED_RFP_LIST, savedRfpList)
}

function* deleteRfp(data) {
    let deleteRfpData = yield deleteRfpApi(data)
    if (deleteRfpData && _.has(deleteRfpData, "data.data")) {
        yield put(deleteRfpResponse(
            SUCCESS_DELETE_RFP,
            deleteRfpData.data
        ))
    } else {
        yield put(deleteRfpResponse(
            ERROR_DELETE_RFP,
            deleteRfpData.data
        ))
    }
}
export function* deleteRfpWatcher() {
    yield takeLatest(DELETE_RFP, deleteRfp)
}

function* applyRfp(data) {
    let applyRfpData = yield applyRfpApi(data)
    if (applyRfpData && _.has(applyRfpData, "data.data")) {
        yield put(applySelectedRfpResponse(
            SUCCESS_APPLY_SELECTED_RFP,
            applyRfpData.data
        ))
    } else {
        yield put(applySelectedRfpResponse(
            ERROR_APPLY_SELECTED_RFP,
            applyRfpData.data
        ))
    }
}

export function* applyRfpWatcher() {
    yield takeLatest(APPLY_SELECTED_RFP, applyRfp)
}

function* rfpDownloadAlert(data) {
    let rfpData = yield rfpDownloadALertApi(data)
    if (rfpData && _.has(rfpData, "data.data")) {
        yield put(rfpDownloadALertResponse(
            SUCCESS_RFP_DOWNLOAD_ALERT,
            rfpData.data
        ))
    } else {
        yield put(rfpDownloadALertResponse(
            ERROR_RFP_DOWNLOAD_ALERT,
            rfpData.data
        ))
    }
}

export function* rfpDownloadAlertWatcher() {
    yield takeLatest(RFP_DOWNLOAD_ALERT, rfpDownloadAlert)
}
function* saveRangeRfpControls(data) {
    let saveRangeRfpControlsData = yield saveRangeRfpWithControlsApi(data)
    if (saveRangeRfpControlsData && _.has(saveRangeRfpControlsData, "data.data")) {
        yield put(saveRangeRfpControlsDataResponse(
            SUCCESS_SAVE_RANGE_RFP_CONTROLS_DATA,
            saveRangeRfpControlsData.data
        ))
    } else {
        yield put(saveRangeRfpControlsDataResponse(
            ERROR_SAVE_RANGE_RFP_CONTROLS_DATA,
            saveRangeRfpControlsData.data
        ))
    }
}
export function* saveRangeRfpControlsWatcher() {
    yield takeLatest(SAVE_RANGE_RFP_CONTROLS_DATA, saveRangeRfpControls)
}
function* updatedRfpList(data) {
    let updatedRfpListData = yield getUpdatedRfpListApi(data)
    if (updatedRfpListData && _.has(updatedRfpListData, "data.data")) {
        yield put(updateRfpControlsDataResponse(
            SUCCESS_UPDATE_RFP_CONTROLS_DATA,
            updatedRfpListData.data
        ))
    } else {
        yield put(updateRfpControlsDataResponse(
            ERROR_UPDATE_RFP_CONTROLS_DATA,
            updatedRfpListData.data
        ))
    }
}
export function* updatedRfpListWatcher() {
    yield takeLatest(UPDATE_RFP_CONTROLS_DATA, updatedRfpList)
}
